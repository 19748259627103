// >> src/features/trainingProgram.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {stringToBoolean} from "../../../utils";

export interface UtilityState {
  isLoading: boolean;
  isModalOpen: boolean;
  modalContent: React.ReactNode | null;
  modalIsCentered: boolean;
  modalCloseOnOverlayClick: boolean;
  dashboardSidebarOpen: boolean;
}
// Define the initial state
const initialState: UtilityState = {
  isModalOpen: false,
  isLoading: false,
  dashboardSidebarOpen: stringToBoolean(localStorage.getItem("dashboardSidebarOpen") as 'true' | 'false'),
  modalContent: null,
  modalIsCentered: false,
  modalCloseOnOverlayClick: true,
};

export const utilitySlice = createSlice({
  name: "utilitySlice",
  initialState,
  reducers: {
    // Define reducers here
    toggleModal: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload ?? !state.isModalOpen;
    },
    toggleDashboardSidebar: (state) => {
      state.dashboardSidebarOpen = !state.dashboardSidebarOpen;
      localStorage.setItem('dashboardSidebarOpen', state.dashboardSidebarOpen.toString());
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setModalContent(
      state,
      action: PayloadAction<{
        content?: React.ReactNode | null | undefined;
        modalState: boolean;
        isCentered?: boolean;
        isLoading?: boolean;
        modalCloseOnOverlayClick?: boolean;
      }>
    ) {
      state.modalContent = !action.payload.content
        ? null
        : action.payload.content;
      state.isModalOpen = action.payload.modalState;
      state.modalIsCentered = !!action.payload.isCentered;
      state.isLoading = action.payload.isLoading ?? false;
      state.modalCloseOnOverlayClick =
        action.payload.modalCloseOnOverlayClick !== undefined
          ? action.payload.modalCloseOnOverlayClick
          : true;
    },
  },
});

// Export actions
export const { toggleModal, setLoading, setModalContent, toggleDashboardSidebar } =
  utilitySlice.actions;
export default utilitySlice.reducer;
