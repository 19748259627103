import {
  Box,
  Slide,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

interface Props {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  children?: React.ReactNode;
}
const MobileSidebar = ({ isOpen, onClose, children }: Props) => {
  return (
    <Slide direction="left" in={isOpen} style={{ zIndex: 11 }}>
      <Box
        pos={"relative"}
        display={{
          base: "block",
          md: "none",
        }}
        w="100%"
      >
        <Box
          pos={"absolute"}
          top={"0"}
          left={"0"}
          h="100vh"
          w={"100vw"}
          bg={useColorModeValue("rgba(0,0,0,0.5)", "rgba(0,0,0,0.5)")}
          onClick={() => onClose(false)}
        ></Box>
      </Box>
      <Box
        color={useColorModeValue("gray.600", "white")}
        blur={
          useBreakpointValue({
            base: useDisclosure().isOpen ? "8px" : "0px",
          }) as string
        }
        backdropFilter={
          useBreakpointValue({
            base: useDisclosure().isOpen ? "blur(35px)" : "unset",
            md: "blur(15px)",
          }) as string
        }
        transition={"all 0.3s"}
        backgroundColor={useColorModeValue("rgba(255, 255, 255)", "gray.800")}
        as="aside"
        w={{ base: "70%" }} // Adjust the width as needed
        h="full"
        position="fixed"
        left="0"
        top="0"
        overflowY="auto"
        pt="5"
        px="6"
      >
        {children}
      </Box>
    </Slide>
  );
};

export default MobileSidebar;
