
import { useState, FormEvent, ChangeEvent } from "react";
import axios from "axios";
import { useAppDispatch } from "../../app/hooks";
import { newsLetterActions } from "../../store/features/slices/newsletter";

type State = "initial" | "submitting" | "success";

export const useNewsletter = () => {
    const [email, setEmail] = useState("");
    const [state, setState] = useState<State>("initial");
    const [error, setError] = useState(false);
    const dispatch = useAppDispatch()
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setError(false);
        setState("submitting");

        try {
            console.log("hello")
            await dispatch(newsLetterActions.subscribe(email))
                .unwrap()
                .then(() => {

                    setState("success");
                })
        } catch (error) {
            setError(true);
            setState("initial");
        }
    };

    return {
        email,
        state,
        error,
        handleChange,
        handleSubmit,
    };
};
