import { useLocation } from "react-router-dom";
import { excludedPaths } from "./excludedPaths";
import NavWithSubnavigation from "../components/Navbar/Navbar";

export const HeaderRoutes = () => {
    const location = useLocation();
  
  
    const shouldRenderHeader = !excludedPaths.some((path) =>
      location.pathname.startsWith(path)
    );
  
    // Render LandingHeader if shouldRenderHeader is true, otherwise null
    return shouldRenderHeader ? <NavWithSubnavigation /> : null;
  };