// Newsletter.tsx

"use client";

import { FormEvent } from "react";
import {
  Stack,
  FormControl,
  Input,
  Button,
  useColorModeValue,
  Heading,
  Text,
  Container,
  Flex,
  Box,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { useNewsletter } from "./Newsletter.hooks";

export default function Newsletter() {
  const { email, state, error, handleChange, handleSubmit } = useNewsletter();

  return (
    <Box
      bg={useColorModeValue("#F2F3F5", "gray.900")}
      w="100%"
      py={{ base: "2em", md: "4em" }}
    >
      <Flex align={"center"} justify={"center"}>
        <Container
          maxW={"lg"}
          boxShadow={"xl"}
          rounded={"lg"}
          p={6}
          bg={useColorModeValue("#white", "whiteAlpha.100")}
        >
          <Heading
            as={"h2"}
            fontSize={{ base: "xl", sm: "2xl" }}
            textAlign={"center"}
            mb={5}
          >
            Subscribe to our Newsletter
          </Heading>
          <Stack
            direction={{ base: "column", md: "row" }}
            as={"form"}
            spacing={"12px"}
            onSubmit={handleSubmit}
          >
            <FormControl>
              <Input
                variant={"solid"}
                borderWidth={1}
                color={"gray.800"}
                _placeholder={{ color: "gray.400" }}
                borderColor={useColorModeValue("gray.300", "gray.700")}
                id={"email"}
                type={"email"}
                required
                placeholder={"Your Email"}
                aria-label={"Your Email"}
                value={email}
                disabled={state !== "initial"}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl w={{ base: "100%", md: "40%" }}>
              <Button
                color={useColorModeValue("white", "gray.50")}
                background={state === "success" ? "green" : "#1944ab"}
                _hover={{ background: "#275cd9" }}
                isLoading={state === "submitting"}
                w="100%"
                type={state === "success" ? "button" : "submit"}
              >
                {state === "success" ? <CheckIcon /> : "Submit"}
              </Button>
            </FormControl>
          </Stack>
          <Text
            mt={2}
            textAlign={"center"}
            fontSize="16px"
            color={error ? "red.500" : "gray.500"}
          >
            {error
              ? "Oh no an error occurred! 😢 Please try again later."
              : "You won't receive any spam! ✌️"}
          </Text>
        </Container>
      </Flex>
    </Box>
  );
}
