"use client";
import {
  Text,
  Flex,
  useColorModeValue,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import LiahLogo from "../../assets/images/LiahTutor_Logo_icon.png";

interface LogoProps {
  liahtutor?: boolean;
  title?: string;
  linkTo?: string;
  onClick?: () => any;
  color?: string;
}
export const Logo = (props: LogoProps) => {
  const { liahtutor = true, linkTo = "/", color } = props;
  return (
    <Flex
      // textAlign={useBreakpointValue({ base: "center", md: "left" })}
      fontFamily={"Montserrat"}
      as={Link}
      to={linkTo}
      alignItems="center"
      fontWeight={800}
      justifyContent="center"
      color={useColorModeValue(color, "white")}
    >
      <Image
        mr=".5rem"
        h="40px"
        transform="scale(2)"
        w="40px"
        objectFit="cover"
        src={LiahLogo}
      />
      {/* box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px; */}
      {liahtutor && (
        <Text
          letterSpacing="2px"
          textTransform="uppercase"
          fontSize="xs"
          fontWeight={500}
          mt="1px"
          as="span"
        >
          LiahTutor
        </Text>
      )}
    </Flex>
  );
};
