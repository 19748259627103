import axios from "axios";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { user } from "../Pages/interfaces/user";
import { Tutor } from "../../types";

// const API_URL = 'https://liah-study-thebackend.onrender.com/tutors';
const API_URL = "localhost:3000/tutors";

class AuthService {
  async signin(email: string, password: string): Promise<Tutor | null> {
    try {
      const response = await axios.post<{ token: string }>(
        `${API_URL}/signin`,
        {
          email,
          password,
        }
      );
      const { token } = response.data;
      const user = this.parseToken(token);
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    } catch (error) {
      console.error("Failed to sign in:", error);
      return null;
    }
  }

  async signup(
    tel: number,
    name: string,
    email: string,
    password: string
  ): Promise<Tutor | null> {
    try {
      const response = await axios.post<{ token: string }>(
        `${API_URL}/signup`,
        {
          tel,
          name,
          email,
          password,
        }
      );
      const { token } = response.data;
      const user = this.parseToken(token);
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    } catch (error) {
      console.error("Failed to sign up:", error);
      return null;
    }
  }

  parseToken(token: string): Tutor | null {
    try {
      const decoded = jwtDecode<JwtPayload & { user: Tutor }>(token);
      return decoded.user;
    } catch (error) {
      console.error("Failed to parse token:", error);
      return null;
    }
  }

  logout(): void {
    localStorage.clear();
    alert("Logged out successfully");
  }

  async googleSSO(): Promise<void> {
    try {
      const response = await axios.get("http://localhost:3000/google/redirect");
      const decoded = jwtDecode(response.data.access_token);
      localStorage.setItem("user", JSON.stringify(decoded));
    } catch (error) {
      console.error("Failed to sign in using Google SSO:", error);
    }
  }

  getCurrentUser(): user | null {
    const userString = localStorage.getItem("user");
    return userString ? JSON.parse(userString) : null;
  }
}

const authService = new AuthService();
export default authService;
