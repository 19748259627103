import "react-phone-number-input/style.css";

import { Flex, Box, FormControl, Button, Text, VStack, useColorMode, useColorModeValue, InputGroup, InputRightElement, IconButton, Progress } from "@chakra-ui/react";
import "./loginStyles.css";
import { Link } from "react-router-dom";
import { Logo } from "../../components/common/Logo";
import { Helmet } from "react-helmet-async";
import PhoneInput from "react-phone-number-input";
import { useSignupLogin } from "../../hooks/auth.hooks";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import 'primereact/resources/primereact.min.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primeicons/primeicons.css';

export default function SignUp() {
  // const toast = useRef<Toast>(null);
  const {msg, onInputChange, passwordStrengthMessagesFixed, inputValues, onSubmit, isLoading, isFormComplete, passwordStrength, passwordError, passwordStrengthMessages, togglePasswordConfirmVisibility, togglePasswordVisibility, showPassword, showPasswordConfirm } =
    useSignupLogin("signup");

    // useEffect(() => {
    //   if (msg) {
    //     toast.current?.show(msg);
    //   }
    // }, [msg]);
  return (
    <VStack
      w={{ base: "100%", md: "50%" }} alignItems="flex-start" justifyContent="space-between" h="full" overflowY="hidden"
      p={{ base: "1px", sm: "2px", md: 4, lg: 5, xl: 6 }}
    >
      <Helmet>
        <title>Sign Up | Liahtutor</title>
        <meta
          name="description"
          content="Tutor platform for LiahStudy Enhence e-learning"
        />
      </Helmet>
      {/* <Toast ref={toast} className="p-button-warning" position="bottom-right" /> */}
      <VStack
        bg={useColorModeValue("white", "black")}
        px="10px"
        rounded={"md"}
        py={10}
        alignItems="flex-start"
        // boxShadow="md"
        w="100%"
        // w={{ base: "100%", sm: "100%", md: "70%", lg: "80%", xl: "70%" }}
        pt={8}
      >
        <Logo />
        <Text textAlign="left" fontSize={"sm"} color={useColorModeValue("gray.500", "white")}>
          Ready to embark on your tutoring adventure?
        </Text>
        <Text color={useColorModeValue("primary", "blue.300")}>Join us today! ✌️</Text>
        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "6px",
          }}
          onSubmit={onSubmit}
        >
          <FormControl>
            <Box
              as="input"
              required
              rounded={"5px"}
              value={inputValues.firstName}
              onChange={onInputChange}
              placeholder={"Enter your first name"}
              _placeholder={{ color: "gray.500", fontSize: "12" }}
              type="text"
              name="firstName"
              _focus={{
                outline: "none",
              }}
              fontSize={{
                base: "xs",
                md: "sm",
              }}
              w="full"
              border={"1px solid gainsboro"}
              bg={useColorModeValue("white", "black")}
              p={"0.5em"}
              borderWidth={"2px"}
            />
          </FormControl>
          <FormControl>
            <Box
              as="input"
              required
              rounded={"5px"}
              placeholder={"Enter your last name"}
              _placeholder={{ color: "gray.500", fontSize: "12" }}
              type="text"
              name="lastName"
              _focus={{
                outline: "none",
              }}
              fontSize={{
                base: "xs",
                md: "sm",
              }}
              w="full"
              value={inputValues.lastName}
              onChange={onInputChange}
              border={"1px solid gainsboro"}
              bg={useColorModeValue("white", "black")}
              p={"0.5em"}
              borderWidth={"2px"}
            />
          </FormControl>
          <FormControl>
            <Flex
              border={"1px solid gainsboro"}
              borderWidth={"2px"}
              rounded={"4px"}
            >
              <Box
                as={PhoneInput}
                required
                border={"none"}
                rounded={"5px"}
                value={inputValues.tel}
                placeholder={"Enter your phone number"}
                _placeholder={{ color: "gray.500", fontSize: "12" }}
                _focus={{
                  outline: "none",
                }}
                fontSize={{
                  base: "xs",
                  md: "sm",
                }}
                w="full"
                h="full"
                onChange={(value) =>
                  onInputChange({
                    target: { name: "tel", value },
                  } as unknown as React.ChangeEvent<HTMLInputElement>)
                }
                p={"0.5em"}
                borderWidth={"2px"}
              />
            </Flex>
          </FormControl>
          <FormControl>
            <Box
              as="input"
              required
              rounded={"5px"}
              placeholder={"Enter your email"}
              _placeholder={{ color: "gray.500", fontSize: "12" }}
              type="email"
              name="email"
              _focus={{
                outline: "none",
              }}
              fontSize={{
                base: "xs",
                md: "sm",
              }}
              w="full"
              value={inputValues.email}
              onChange={onInputChange}
              border={"1px solid gainsboro"}
              bg={useColorModeValue("white", "black")}
              p={"0.5em"}
              borderWidth={"2px"}
            />
          </FormControl>
          <FormControl>
            <InputGroup>
              <Box
                as="input"
                required
                rounded={'5px'}
                placeholder={'Enter your password'}
                _placeholder={{ color: 'gray.500', fontSize: '12' }}
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={inputValues.password}
                _focus={{
                  outline: 'none',
                }}
                fontSize={{
                  base: 'xs',
                  md: 'sm',
                }}
                w="full"
                border={'1px solid gainsboro'}
                bg={useColorModeValue('white', 'black')}
                p={'0.5em'}
                onChange={onInputChange}
                borderWidth={'2px'}
              />
              <InputRightElement width="3rem">
                <IconButton
                  h="1.5rem"
                  variant="unstyled"
                  size="sm"
                  onClick={togglePasswordVisibility}
                  icon={showPassword ? <ViewOffIcon /> : <ViewIcon />} aria-label={"Toggle Password visibility"} />
              </InputRightElement>
            </InputGroup>
            {(passwordStrength < 3) && <Text fontSize="xs" color="red.500" mt={1}>
              {passwordStrengthMessages}
            </Text>}
            {passwordStrength > 0 && (
              <Progress
                value={passwordStrength * 25}
                size="xs"
                colorScheme={
                  passwordStrength < 2 ? 'red' :
                    passwordStrength < 3 ? 'yellow' :
                      'green'
                }
                mt="1"
              />
            )}
            {passwordStrength > 0 && (
              <Text fontSize="xs" color={passwordStrength < 3 ? 'red.500' : 'green.500'}>
                Password strength: {passwordStrengthMessagesFixed[passwordStrength]}
              </Text>
            )}
          </FormControl>
          <FormControl>
            <InputGroup>
              <Box
                as="input"
                required
                rounded={'5px'}
                placeholder={'Retype your password'}
                _placeholder={{ color: 'gray.500', fontSize: '12' }}
                type={showPasswordConfirm ? 'text' : 'password'}
                name="passwordConfirm"
                value={inputValues.passwordConfirm}
                _focus={{
                  outline: 'none',
                }}
                fontSize={{
                  base: 'xs',
                  md: 'sm',
                }}
                w="full"
                border={'1px solid gainsboro'}
                bg={useColorModeValue('white', 'black')}
                p={'0.5em'}
                onChange={onInputChange}
                borderWidth={'2px'}
              />
              <InputRightElement width="3rem">
                <IconButton
                  h="1.5rem"
                  size="sm"
                  variant="unstyled"
                  onClick={togglePasswordConfirmVisibility}
                  icon={showPasswordConfirm ? <ViewOffIcon /> : <ViewIcon />} aria-label={""} />
              </InputRightElement>
            </InputGroup>
            {passwordError && (
              <Text fontSize="xs" color="red.500">
                {passwordError}
              </Text>
            )}
          </FormControl>
          <Text fontSize={12}>
            By signing up, I accept the Liah Technologies{" "}
            <Text as="span" color={useColorModeValue("primary", "blue.300")} cursor={"pointer"}>
              Terms of Service
            </Text>{" "}
            and acknowledge the{" "}
            <Text as="span" color={useColorModeValue("primary", "blue.300")} cursor={"pointer"}>
              Privacy Policy
            </Text>
            .
          </Text>
          <Button
            type="submit"
            variant={"primary"}
            w="100%"
            isLoading={isLoading}
            loadingText="Submitting"
            isDisabled={!isFormComplete()}
          >
            Sign up
          </Button>
          <Flex justifyContent={"flex-end"} fontSize={12}>
            <Text as={Link}>Do you have an account?</Text>
            <Text
              as={Link}
              px={2}
              to="/signin"
              color={useColorModeValue("primary", "blue.300")}
              cursor={"pointer"}
              _hover={{
                textDecoration: "underline",
              }}
            >
              Sign in
            </Text>
          </Flex>
        </form>
      </VStack>
      {/* <Box bg="rgba(255, 255, 255, 0.2)" p=".1rem" rounded="lg" boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)" backdropFilter="blur(5px)" border="1px solid rgba(255, 255, 255, 0.3)">
        <Logo />
      </Box> */}
    </VStack>
  );
}
