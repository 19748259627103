import { createListenerMiddleware, type AsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "../../../store";
import { principalTPKey } from "../../../../constants";
import { programActions } from "../../slices/trainingPrograms";

const listenerMiddleware = createListenerMiddleware();
const combinedUserActions = [
  ...Object.values(programActions).map(
    (a) => (a as unknown as AsyncThunk<unknown, unknown, object>).fulfilled ?? a
  ),
];

combinedUserActions.forEach((cAction) => {
  listenerMiddleware.startListening({
    actionCreator: cAction,
    effect: (action, listenerApi) => {
      if (cAction.type === action.type) {
        const currentTPAsString = JSON.stringify(
          (listenerApi.getState() as unknown as RootState).trainingProgram
        );
        window.localStorage.setItem(
          principalTPKey,
          currentTPAsString
        );
      }
    },
  });
});

export default listenerMiddleware.middleware;
