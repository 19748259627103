// Array of paths where navigationBar should NOT be shown
export const excludedPaths = [
    "/signin",
    "/signup",
    "/onboarding",
    "/dashboard",
    "/profile",
    "/account/",
    "/communication",
    "/performance",
    "/create-program",
    "/learn-about-creation",
    "/forgot-password",
    "/program/",
  ];
  