import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { AuthIntent, ErrorResponse } from '../types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Toast, ToastMessage } from 'primereact/toast';

import zxcvbn from 'zxcvbn';
import { useAppDispatch } from '../app/hooks';
import { userActions } from '../store/features/slices/authSlice';
import { TSignUpUser } from '../api/user.api';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { principalStorageKey, principalTokenKey } from '../constants';
import { programActions } from '../store/features/slices/trainingPrograms';

export const useSignupLogin = (intent: AuthIntent) => {
    const toast = useToast();
    // const toast = useRef<Toast>(null);
    const [error, setError] = useState("");
    const [msg, setMsg] = useState<ToastMessage | null>(null)
    const [passwordError, setPasswordError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [inputValues, setInputValues] = useState<TSignUpUser>({
        email: '',
        password: '',
        passwordConfirm: '',
        firstName: '',
        lastName: '',
        tel: '',
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const [passwordStrengthMessages, setPasswordStrengthMessages] = useState<string[]>([]);

    const passwordStrengthMessagesFixed = [
        'Very weak',
        'Weak',
        'Fair',
        'Strong',
        'Very strong',
    ];
    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const togglePasswordConfirmVisibility = () => setShowPasswordConfirm(!showPasswordConfirm);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setInputValues((values) => ({
            ...values,
            [name]: value,
        }));
        if (e.target.name === "email") {
            setIsValid(e.target.validity.valid);
        }
        if (e.target.name === "password") {
            const strength = zxcvbn(value).score;
            setPasswordStrength(strength);
        }

        if (name === "password") {
            const result = zxcvbn(value);
            setPasswordStrength(result.score);
            setPasswordStrengthMessages(result.feedback.suggestions.length > 0
                ? result.feedback.suggestions
                : ["Use a strong password with a mix of letters, numbers, and symbols."]);
        }
    };


    useEffect(() => {
        if (intent === "signup") {
            if (inputValues.password !== inputValues.passwordConfirm) {
                setPasswordError("Passwords do not match");
            } else {
                setPasswordError("");
            }
        }
    }, [inputValues.password, inputValues.passwordConfirm, intent]);

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            if (intent === 'signin') {
                await dispatch(userActions.signinUser(inputValues))
                    .unwrap()
                    .then((res) => {
                        setError("");
                        setIsLoading(false);
                        const token = res.token;
                        if (token) {
                            dispatch(programActions.getMyTrainingPrograms(token));
                        }
                        setMsg({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'You have successfully signed in',
                            life: 5000,
                        })

                        navigate("/dashboard");
                    });

            } else if (intent === 'signup') {
                if (passwordStrength < 3) {
                    setPasswordError("Password is too weak");
                    setIsLoading(false);
                    return;
                }
                await dispatch(userActions.signUpUser(inputValues))
                    .unwrap()
                    .then(() => {
                        setError("");
                        setIsLoading(false);

                        navigate("/auth/verify-otp");
                    });
                setMsg({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'You have successfully signed up',
                    life: 5000,
                });
            }
        } catch (error: any) {
            console.log(error);

            const isErrorResponse = (err: unknown): err is ErrorResponse => {
                return typeof err === 'object' && err !== null &&
                    'status' in err && 'error' in err && 'message' in err && 'stack' in err;
            };
            console.log("errR:", isErrorResponse(error))
            let message: string;

            if (isErrorResponse(error)) {
                message = error.message;
            } else if (error instanceof Error) {
                message = error.message;
            } else {
                message = "Please check your internet connection";
            }

            setError(error?.message);
            toast({
                title: error?.status,
                description: error?.message,
                status: "error",
                duration: 5000,
                isClosable: false,
            });
            setMsg({
                severity: 'warn',
                summary: 'Fail',
                detail: error?.message as ErrorResponse['message'],
                life: 5000,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const isFormComplete = () => {
        const { email, password, passwordConfirm, firstName, lastName, tel } = inputValues;

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (intent === "signup") {
            return (
                email !== "" &&
                emailRegex.test(email) &&
                password !== "" &&
                passwordConfirm !== "" &&
                firstName !== "" &&
                lastName !== "" &&
                tel !== "" &&
                password === passwordConfirm &&
                passwordStrength >= 3
            );
        } else {
            return email !== "" && emailRegex.test(email) && password !== "";
        }
    };


    return {
        msg, inputValues, passwordStrengthMessagesFixed, error, passwordError, isValid, onSubmit, isLoading, onInputChange, isFormComplete, passwordStrength, passwordStrengthMessages, togglePasswordConfirmVisibility, togglePasswordVisibility, showPassword, showPasswordConfirm
    }
};

export const useOtpConfirmation = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [emailToUse, setEmailToUse] = useState("");
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const value1 = useRef<HTMLInputElement | null>(null);
    const value2 = useRef<HTMLInputElement | null>(null);
    const value3 = useRef<HTMLInputElement | null>(null);
    const value4 = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        const userInfoString = localStorage.getItem(principalStorageKey);
        if (userInfoString) {
            const userInfo = JSON.parse(userInfoString);
            const email = userInfo.payload?.user?.email;
            setEmailToUse(email);
        }
    }, []);
    const handleConfirmOTP = async () => {
        if (
            !value1.current?.value ||
            !value2.current?.value ||
            !value3.current?.value ||
            !value4.current?.value
        ) {
            toast({
                title: "Invalid OTP",
                description: "Enter a valid OTP",
                status: "error",
                duration: 5000,
                isClosable: false,
            });
            return;
        }
        setIsLoading(true);

        if (emailToUse) {
            await dispatch(
                userActions.verifyOtpUser({
                    otp: `${value1.current?.value}${value2.current?.value}${value3.current?.value}${value4.current?.value}`,
                    email: emailToUse,
                })
            )
                .unwrap()
                .then((res) => {
                    setError("");
                    setIsLoading(false);
                    toast({
                        title: "Success",
                        description: "You have successfully signed in",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                    console.log(res)
                    // const token = res.token;
                    //     if (token) {
                    //         dispatch(programActions.getMyTrainingPrograms(token));
                    //     }
                    navigate("/dashboard", {
                        state: {
                            otp: `${value1.current?.value}${value2.current?.value}${value3.current?.value}${value4.current?.value}`,
                            from: "/verify-otp",
                        },
                    });
                })
                .catch((error) => {
                    setError(error.message || "Error confirming OTP");
                    setIsLoading(false);
                    toast({
                        title: "Error",
                        description: error.message || "Error confirming OTP",
                        status: "error",
                        duration: 5000,
                        isClosable: false,
                    });
                });
        } else {
            console.log("No user info found in local storage");
            setIsLoading(false);
        }

        setIsLoading(false);

        if (error) {
            return toast({
                title: "Error",
                description: "Error confirming OTP",
                status: "error",
                duration: 5000,
                isClosable: false,
            });
        }
    };

    return {
        isLoading,
        handleConfirmOTP,
        value1,
        value2,
        value3,
        value4,
        emailToUse,
    };
};

const emailValidationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is required"),
});


const passwordValidationSchema = Yup.object({
    password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), ''], "Passwords must match")
        .required("Confirm password is required"),
});

export const useForgotPassword = () => {
    const toast = useToast()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [step, setStep] = useState<'email' | 'otp' | 'password'>('email');
    const [serverError, setServerError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    // Refs for the OTP input fields
    const value1 = useRef<HTMLInputElement>(null);
    const value2 = useRef<HTMLInputElement>(null);
    const value3 = useRef<HTMLInputElement>(null);
    const value4 = useRef<HTMLInputElement>(null);

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordStrengthMessages, setPasswordStrengthMessages] = useState<string[]>([]);

    const passwordStrengthMessagesFixed = [
        'Very weak',
        'Weak',
        'Fair',
        'Strong',
        'Very strong',
    ];

    // Toggle password visibility
    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const togglePasswordConfirmVisibility = () => setShowPasswordConfirm(!showPasswordConfirm);

    // Password strength checking logic
    const checkPasswordStrength = (value: string) => {
        const result = zxcvbn(value);
        setPasswordStrength(result.score);
        setPasswordStrengthMessages(result.feedback.suggestions.length > 0
            ? result.feedback.suggestions
            : ["Use a strong password with a mix of letters, numbers, and symbols."]);
    };

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: emailValidationSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            setServerError(null);

            try {
                await dispatch(userActions.recoverPassword(values))
                    .unwrap()
                    .then((res) => {
                        setIsLoading(false);
                        setStep('otp');
                        // const token = res.token;
                        // if (token) {
                        //     dispatch(programActions.getMyTrainingPrograms(token));
                        // }


                    });
                setIsSubmitted(true);
            } catch (error) {
                setServerError("Failed to send reset link. Please try again later.");
            } finally {
                setIsLoading(false);
            }
        },
    });
    const handleOTPSubmit = async () => {
        setIsLoading(true);
        setServerError(null);

        // Get OTP value from the refs
        const otp = [
            value1.current?.value,
            value2.current?.value,
            value3.current?.value,
            value4.current?.value,
        ].join('');

        // Validate OTP length
        if (otp.length !== 4) {
            setServerError("Please enter a valid 4-character OTP.");
            setIsLoading(false);
            return;
        }

        try {
            // Dispatch the action to verify OTP
            const response = await dispatch(
                userActions.verifyOtpUser({
                    otp,
                    email: formik.values.email,
                })
            ).unwrap();

            setServerError(null);
            toast({
                title: "Success",
                description: "OTP Correct",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            console.log(response);

            setStep('password'); // Move to the password reset step

        } catch (error: any) {
            setServerError(error.message || "Error confirming OTP");
            toast({
                title: "Error",
                description: error.message || "Error confirming OTP",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    // Formik for password reset
    const passwordFormik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: passwordValidationSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            setServerError(null);

            try {
                const response = await dispatch(
                    userActions.resetPasswordUser({
                        email: formik.values.email,
                        password: passwordFormik.values.password,
                        confirmPassword: passwordFormik.values.confirmPassword
                    })
                ).unwrap();
    
                setServerError(null);
                toast({
                    title: "Success",
                    description: "You have reset your password",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                // navigate('/dashboard')
                // console.log(response);
                setIsSubmitted(true); // Successfully reset password
            } catch (error) {
                setServerError("Failed to reset password. Please try again.");
                toast({
                    title: "Error",
                    description: "Failed to reset password. Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } finally {
                setIsLoading(false);
            }
        },
    });

    // Handle password input change and calculate strength
    const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        passwordFormik.setFieldValue("password", value);

        const result = zxcvbn(value);
        setPasswordStrength(result.score);
        setPasswordStrengthMessages(
            result.feedback.suggestions.length > 0
                ? result.feedback.suggestions
                : ["Use a strong password with a mix of letters, numbers, and symbols."]
        );
    };
    return {
        formik,             // Formik instance for the email input
        passwordFormik,     // Formik instance for the password input
        isLoading,          // Loading state for API interactions
        serverError,        // Server error handling
        isSubmitted,        // Flag indicating if the password reset is complete
        step,               // The current step in the flow
        togglePasswordVisibility, // Toggle password visibility
        togglePasswordConfirmVisibility, // Toggle confirm password visibility
        showPassword,            // Password visibility state
        showPasswordConfirm,     // Confirm password visibility state
        passwordStrength,        // Password strength score
        passwordStrengthMessages, // Password strength feedback messages
        passwordStrengthMessagesFixed, // Fixed messages for strength levels
        onPasswordChange,        // Function to handle password input changes
        handleOTPSubmit, // Handler for OTP form submission
        checkPasswordStrength,
        value1, value2, value3, value4,  // Refs for the OTP input fields
        handlePasswordSubmit: passwordFormik.handleSubmit, // Handler for password reset form submission
    };
};