import React from 'react';
import {
    Button,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import {WarningIcon} from '@chakra-ui/icons';
import {Link} from 'react-router-dom';
import {ErrorType} from "../../../types";



const ErrorModal: React.FC<{ isOpen: boolean; onClose: () => void; error: ErrorType }> = ({ isOpen, onClose, error }) => {
    const modalBgColor = useColorModeValue('white', 'gray.800');
    const modalTextColor = useColorModeValue('gray.700', 'gray.300');

    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent bg={modalBgColor} color={modalTextColor}>
                <ModalHeader as={HStack}>
                    <Icon as={error.icon || WarningIcon} color="orange.500" boxSize={6} mr={2} />
                    <Text>{error.title}</Text>
                </ModalHeader>
                <ModalBody>
                    <Text mb={4}>
                        {error.message}
                    </Text>
                </ModalBody>
                <ModalFooter>
                    {error.buttonLink ? (
                        <Button
                            variant='primary'
                            mr={3}
                            as={Link}
                            to={error.buttonLink}
                            onClick={error.buttonAction}
                        >
                            {error.buttonText || 'Proceed'}
                        </Button>
                    ) : (
                        <Button
                            variant='primary'
                            mr={3}
                            onClick={error.buttonAction || onClose}
                        >
                            {error.buttonText || 'Close'}
                        </Button>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ErrorModal;
