import { Box, Button, Center, Image, Text } from "@chakra-ui/react";
import React from "react";
import NotFound from "./assets/notfound.png";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
// import {  } from "react-router-dom";

type Props = {
  button?: boolean;
  message?: string;
};

const Notfound = ({ button = true, message }: Props) => {
  //Return a fully styled notfound page using chakra ui
  const navigate = useNavigate();
  return (
    <Box h="100vh">
      <Helmet>
        <title>Not Found | Liahtutor</title>
        <meta
          name="description"
          content="The page you are requesting doesn't exist or moved to a new location"
        />
      </Helmet>
      <Center h="inherit" flexDirection={"column"}>
        <Image src={NotFound}></Image>
        <Text my="1em">{message ? message : `Page not Found`}</Text>
        {button ? (
          <Button variant={"primary"} onClick={() => navigate(-1)}>
            Back
          </Button>
        ) : null}
      </Center>
    </Box>
  );
};

export default Notfound;
