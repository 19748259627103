import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TrainingProgramIdState {
    programId: string | null;
}
const initialState: TrainingProgramIdState = {
    programId: null
};

const trainingProgramIdSlice = createSlice({
    name: "trainingProgramId",
    initialState,
    reducers: {
        saveId(state, action: PayloadAction<{ programId: string }>) {
            state.programId = action.payload.programId;
        },
        removeId(state, action: PayloadAction<{ programId: string }>) {
            state.programId = null
        },

    },
});

export const { saveId, removeId } = trainingProgramIdSlice.actions;
export default trainingProgramIdSlice.reducer;
