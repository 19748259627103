import studentzIcon from "../assets/images/studentzIcon.svg"
import programzIcon from "../assets/images/programzIcon.png"
import creditCardIcon from "../assets/images/CreditCard.svg"
import avRatingIcon from "../assets/images/avRatingIcon.svg"


export const principalStorageKey = "_liah_tutor_principal_storage";
export const principalTPKey = "_liah_tutor_training_program";
export const currentPrincipalTPKey = "_liah_tutor_current_training_program"
export const currentSavedNotesKey = "_liah_tutor_current_savedNotes";
export const principalTokenKey = "_token"
export const principalRefreshTokenKey = "_refresh_token"
export const stepsConfig = [
  {
    title: "Program Name",
    description: "Description and price",
    path: "",
  },
  {
    title: "Course",
    description: "Courses in this program",
    path: "course",
  },
  {
    title: "Module",
    description: "Add module to the various course",
    path: "courses-module",
  },
  {
    title: "Lesson",
    description: "Add lessons to the module(s) you have created",
    path: "courses-lesson",
  },
  {
    title: "Intro Video",
    description: "Introductory video for this program",
    path: "intro-video",
  },
  {
    title: "Save and Publish",
    description: "Save and publish the program",
    path: "save-and-publish-program",
  },
];
export enum ErrorTypes {
  NETWORK_ERROR = "NETWORK_ERROR",
  AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

// Interface for an error object
export interface AppError {
  type: ErrorTypes;
  message: string;
  details?: string; // Optional: Additional details about the error
}

// Error messages
export const Errors = {
  NETWORK_ERROR: {
    type: ErrorTypes.NETWORK_ERROR,
    message: "A network error occurred. Please try again later.",
  },
  AUTHENTICATION_ERROR: {
    type: ErrorTypes.AUTHENTICATION_ERROR,
    message: "Authentication failed. Please check your credentials.",
  },
  TOKEN_EXPIRED: {
    type: ErrorTypes.AUTHENTICATION_ERROR,
    message: "Your session has expired. Please log in again.",
  },
  VALIDATION_ERROR: {
    type: ErrorTypes.VALIDATION_ERROR,
    message: "Some fields contain invalid data. Please review your input.",
  },
  UNKNOWN_ERROR: {
    type: ErrorTypes.UNKNOWN_ERROR,
    message: "An unexpected error occurred. Please try again later.",
  },
};
export const formatedStatistics = (trainingProgramsPayload: any) => {
  return [
    {
      value: trainingProgramsPayload?.trainingProgram?.totalStudent,
      label: "Students",
      linkTo: "performance",
      icon: studentzIcon
    },

    {
      value: trainingProgramsPayload?.trainingProgram?.totalPrograms,
      label: "Programs",
      linkTo: "programs",
      icon: programzIcon
    },
    {
      value: trainingProgramsPayload?.trainingProgram?.averageRating,
      label: "Av. Rating",
      linkTo: "#",
      icon: avRatingIcon
    },
    {
      value: trainingProgramsPayload?.trainingProgram?.totalRevenue,
      label: "Total Revenue",
      format: "XAF",
      linkTo: "performance",
      icon: creditCardIcon
    },
  ];
}
export const customHeaderSecret = "_this-Is-THAT-KEY-you-will-SWEat-tO-Get";
export const customHeaderKey = "x-custom-header";

export const curentBackend =
  // "http://localhost:8000"
"https://liah-edu-server.liahstudy.online/"
// "https://liah-study-thebackend.onrender.com"

