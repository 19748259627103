import { Link, useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import { FaAngleDown } from "react-icons/fa";

import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
// import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { user as typeUser } from "../../Pages/interfaces/user";
import authService from "../../services/auth.service";
import { useEffect, useState } from "react";
import UserAvatar from "../UserProfile/UserAvatar";
import { navItems } from "./Nav";
import { Logo } from "../common/Logo";
import { useAppSelector } from "../../app/hooks";

export default function NavWithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();
  const [redirect, setRedirect] = useState("");
  const { payload } = useAppSelector((state) => state.auth);
  const [scrollY, setScrollY] = useState(0);
  console.log(payload)
  const location = useLocation();
  useEffect(() => {
    const currentUser: typeUser | null = authService.getCurrentUser();
    if (currentUser) {
      setRedirect("/dashboard");
    }
  }, [redirect]);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isScrolled = scrollY > 70;
  const bg = isScrolled ? "rgba(255, 255, 255, 0.95)" : "rgba(255, 255, 255, 0.2)";
  const border = isScrolled ? "1px solid rgba(0, 0, 0, 0.1)" : "1px solid rgba(255, 255, 255, 0.3)";
  // const color = isScrolled ? "black" : "white"

  return (
    <Box
      zIndex={29} pos="fixed" top={0} right={0} left={0}>
      <Flex
        fontFamily="Montserrat"
        // bg={useColorModeValue("white", "gray.800")} Infinite number of Problems, What is science? Ability to know you don't know
        bg={bg}

        border={border}
        backdropBlur="5px"
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        pl={{ sm: "2%", md: "10%" }}
        pr={{ sm: "2%", md: "10%" }}
        boxShadow="sm"
        align={"center"}
        maxW="100%"
        overflow="hidden"
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <HStack flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Logo color={isScrolled ? "black" : "white"} />

          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
        </HStack>
        {!payload?.user ? (
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
            fontSize="xs"
          >
            <Button
              as={Link}
              fontSize={"inherit"}
              fontWeight={600}
              variant={"outline"}
              to={"/signin"}
              color="black"
              _hover={{
                textDecoration: "none",
              }}
            >
              Sign in
            </Button>
            <Button
              as={Link}
              variant="primary"
              display={{ base: "none", md: "inline-flex" }}
              to={"/signup"}
              fontSize="xs"
            >
              Start For Free
            </Button>
          </Stack>
        ) : (
          <HStack>
            <Button
              as={Link}
              variant="primary"
              display={{ base: "none", md: "inline-flex" }}
              to={"/dashboard"}
              fontSize="xs"
            >
              Dashboard
            </Button>
            <UserAvatar navItems={navItems} />
          </HStack>
        )}{" "}
        {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

export const DesktopNav = () => {
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isScrolled = scrollY > 70;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const linkColor = isScrolled ? useColorModeValue("gray.500", "gray.200") : useColorModeValue("white", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");
  const [hovered, setHovered] = useState(false);
  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <HStack
                as={Link}
                p={2}
                to={navItem.to ?? "/"}
                fontSize={"xs"}
                fontWeight={600}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
                onMouseOver={() => {
                  if (navItem.children) setHovered(true);
                }}
                onMouseLeave={() => {
                  if (navItem.children) setHovered(false);
                }}
              >
                <Text>{navItem.label}</Text>
                {navItem.children ? (
                  <Box
                    as={FaAngleDown}
                    color="#082D85"
                    transform={hovered ? "rotate(180deg)" : "rotate(0deg)"}
                    transition="transform 0.3s ease-in-out"
                  />
                ) : null}
              </HStack>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                fontSize={"xs"}
                rounded={"xl"}
                minW={"sm"}
                onMouseOver={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, to, subLabel }: NavItem) => {
  return (
    <Box
      as={Link}
      to={to}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("blue.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"left"} alignItems="left">
        <Box textAlign="left">
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "blue.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"blue.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  );
};

export const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, to }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <HStack
        py={2}
        as={Link}
        to={to ?? "/"}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          fontSize={"sm"}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </HStack>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Box
                fontSize={"10px"}
                as={Link}
                key={child.label}
                py={2}
                to={child.to}
              >
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  to?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Company",
    children: [
      {
        label: "About Liah Technologies",
        subLabel: "Empowering knowledge sharing to shape a brighter future.",
        to: "/about",
      },
      {
        label: "Our Data handling Details",
        subLabel: "Dealing with your precious data",
        to: "/data-handling",
      },
    ],
  },
  {
    label: "FAQ",
    to: "/faq",
  },
  {
    label: "Institutions",
    to: "/institutions",
  },
];
