import React from "react";
import { HStack, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import Carousel from "../components/Carousel/Carousel";
import { principalTokenKey } from "../constants";

type AuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  // const navigate = useNavigate();
  // const { payload } = useAppSelector((state) => state.auth);

  // React.useEffect(() => {
  //   const token = localStorage.getItem(principalTokenKey)
  //   if (!(payload?.user && payload?.user.emailVerified === true && token)) {
  //     navigate("/signin");
  //   }
  // }, [payload, navigate]);

  return (
    <HStack h="100vh" m={0}>
      {children}
      <Box display={{ base: "none", md: "inline-block" }} h="100vh" w="70%" overflow="hidden">
        <Carousel />
      </Box>
    </HStack>
  );
};

export default AuthLayout;
