import { forwardRef } from "react";
import { useUserProfile } from "./user-profile.hooks";
import { Avatar, Box, BoxProps, useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const UserImage = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const user = useUserProfile();
  const navigate = useNavigate();

  return (
    <Box as="button" onClick={() => navigate("/profile")} ref={ref} {...props}>
      {user && (
        <Avatar
          name={`${user.firstName} ${user.lastName}`}
          src={user.photoURL}
          size="sm"
          title={`${user.firstName} ${user.lastName}`}
          cursor="pointer"
          // eslint-disable-next-line react-hooks/rules-of-hooks
          border={useColorModeValue("1px inset blue","1px solid white")}
          _hover={{
            boxShadow: "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
          }}
        />
      )}
    </Box>
  );
});

export default UserImage;