import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiException } from '../../../types';
import {
    createATP01,
    createATP02,
    getCourses,
    getSingleTrainingProgram,
    updateTrainingProgram,
    deleteTrainingProgram,
    getACourse,
    updateCourse,
    deleteCourse,
    addModule,
    getModules,
    getAModule,
    updateModule,
    deleteModule,
    addLesson,
    getLessons,
    getALesson,
    updateLesson,
    deleteLesson,
    addQuiz,
    getQuizzes,
    getAQuiz,
    updateQuiz,
    deleteQuiz,
    addTheIntroVideo,
    publishTP,
    getCategories,
    getTags,
} from '../../../api/trainingProgram.api';
import {
    Course,
    LessonsState,
    Module,
    Quiz,
    TrainingProgram,
    createTPData01,
    createTPData02,
    enteredLessonData,
    enteredModuleData,
    enteredQuizData,
    lessonGroup,
    updateTrainingProgramType,
} from '../../../../types';
import { AsyncStateTP, retrieveErrorResponse } from '../../../utils';

// Define the initial state
const initialState: AsyncStateTP<Partial<TrainingProgram>, ApiException> = {
    loading: true,
    uploadProgress: 0,
    payload: {},
    errors: [],
};



export const createTP01 = createAsyncThunk(
    'programs/createProgram',
    async (data: createTPData01, thunkApi) => {
        try {
            const info = await createATP01(data, (progress) => {
                thunkApi.dispatch(setUploadProgress(progress));
            });
            return info;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const createTP02 = createAsyncThunk(
    'programs/addCourse',
    async (data: createTPData02, thunkApi) => {
        try {
            const progressCallback = (progress: number) => {
                thunkApi.dispatch(setUploadProgress(progress));
            };

            const info = await createATP02(data, progressCallback);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const getATPCourses = createAsyncThunk(
    'programs/getATPCourses',
    async (tpId: string, thunkApi) => {
        try {
            const info = await getCourses(tpId);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const getSingleProgram = createAsyncThunk(
    'programs/getSingleProgram',
    async (id: string, thunkApi) => {
        try {
            const info = await getSingleTrainingProgram(id);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const updateTP = createAsyncThunk(
    'programs/updateProgram',
    async (data: updateTrainingProgramType, thunkApi) => {
        try {
            const info = await updateTrainingProgram(data, (progress) => {
                thunkApi.dispatch(setUploadProgress(progress));
            });
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const deleteTP = createAsyncThunk(
    'programs/deleteProgram',
    async (id: string, thunkApi) => {
        try {
            const info = await deleteTrainingProgram(id);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const getSingleCourse = createAsyncThunk(
    'courses/getSingleCourse',
    async (ids: { id: string, courseId: string }, thunkApi) => {
        try {
            const info = await getACourse(ids.id, ids.courseId);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const updateCourseThunk = createAsyncThunk(
    'courses/updateCourse',
    async (data: { ids: { id: string, courseId: string }; courseData: Partial<Course> }, thunkApi) => {
        try {
            const info = await updateCourse(data.courseData, data.ids.id, data.ids.courseId,);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const deleteCourseThunk = createAsyncThunk(
    'courses/deleteCourse',
    async (ids: { id: string, courseId: string }, thunkApi) => {
        try {
            const info = await deleteCourse(ids.id, ids.courseId);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const addModuleThunk = createAsyncThunk(
    'modules/addModule',
    async (data: { data: enteredModuleData, ids: { id: string, courseId: string } }, thunkApi) => {
        try {
            const progressCallback = (progress: number) => {
                thunkApi.dispatch(setUploadProgress(progress));
            };
            const info = await addModule(data.data, data.ids.id, data.ids.courseId, progressCallback);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const getModulesThunk = createAsyncThunk(
    'modules/getModules',
    async (ids: { id: string, courseId: string }, thunkApi) => {
        try {
            const info = await getModules(ids.id, ids.courseId);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const getSingleModule = createAsyncThunk(
    'modules/getSingleModule',
    async (ids: { id: string, courseId: string, moduleId: string }, thunkApi) => {
        try {
            const info = await getAModule(ids.id, ids.courseId, ids.moduleId);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const updateModuleThunk = createAsyncThunk(
    'modules/updateModule',
    async (data: { ids: { id: string, courseId: string, moduleId: string }; moduleData: Partial<Module> }, thunkApi) => {
        try {
            const info = await updateModule(data.moduleData, data.ids.id, data.ids.courseId, data.ids.moduleId);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const deleteModuleThunk = createAsyncThunk(
    'modules/deleteModule',
    async (ids: { id: string, courseId: string, moduleId: string }, thunkApi) => {
        try {
            const info = await deleteModule(ids.id, ids.courseId, ids.moduleId);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const addLessonThunk = createAsyncThunk(
    'lessons/addLesson',
    async (data: { data: LessonsState, currentTPId: string }, thunkApi) => {
        try {
            const progressCallback = (progress: number) => {
                thunkApi.dispatch(setUploadProgress(progress));
            };
            const info = await addLesson(data.data, data.currentTPId, progressCallback);
            return info;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const getLessonsThunk = createAsyncThunk(
    'lessons/getLessons',
    async (ids: { id: string, courseId: string, moduleId: string }, thunkApi) => {
        try {
            const info = await getLessons(ids.id, ids.courseId, ids.moduleId);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const getSingleLesson = createAsyncThunk(
    'lessons/getSingleLesson',
    async (ids: { id: string, courseId: string, moduleId: string, lessonId: string }, thunkApi) => {
        try {
            const info = await getALesson(ids.id, ids.courseId, ids.moduleId, ids.lessonId);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const updateLessonThunk = createAsyncThunk(
    'lessons/updateLesson',
    async (data: { ids: { id: string, courseId: string, moduleId: string, lessonId: string }; lessonData: Partial<enteredLessonData> }, thunkApi) => {
        try {
            const info = await updateLesson(data.lessonData, data.ids.id, data.ids.courseId, data.ids.moduleId, data.ids.lessonId);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const deleteLessonThunk = createAsyncThunk(
    'lessons/deleteLesson',
    async (ids: { id: string, courseId: string, moduleId: string, lessonId: string }, thunkApi) => {
        try {
            const info = await deleteLesson(ids.id, ids.courseId, ids.moduleId, ids.lessonId);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const addQuizThunk = createAsyncThunk(
    'quizzes/addQuiz',
    async (data: { ids: { id: string, courseId: string, moduleId: string, lessonId: string }, quizData: enteredQuizData }, thunkApi) => {
        try {
            const progressCallback = (progress: number) => {
                thunkApi.dispatch(setUploadProgress(progress));
            };
            const info = await addQuiz(data.quizData, data.ids.id, data.ids.courseId, data.ids.moduleId, data.ids.lessonId, progressCallback);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const getQuizzesThunk = createAsyncThunk(
    'quizzes/getQuizzes',
    async (ids: { id: string, courseId: string, moduleId: string, lessonId: string, quizId: string }, thunkApi) => {
        try {
            const info = await getQuizzes(ids.id, ids.courseId, ids.moduleId, ids.lessonId);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const getSingleQuiz = createAsyncThunk(
    'quizzes/getSingleQuiz',
    async (ids: { id: string, courseId: string, moduleId: string, lessonId: string, quizId: string }, thunkApi) => {
        try {
            const info = await getAQuiz(ids.id, ids.courseId, ids.moduleId, ids.lessonId, ids.quizId);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const updateQuizThunk = createAsyncThunk(
    'quizzes/updateQuiz',
    async (data: { ids: { id: string, courseId: string, moduleId: string, lessonId: string, quizId: string }, quizData: Partial<Quiz> }, thunkApi) => {
        try {
            const info = await updateQuiz(data.quizData, data.ids.id, data.ids.courseId, data.ids.moduleId, data.ids.lessonId, data.ids.quizId);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const deleteQuizThunk = createAsyncThunk(
    'quizzes/deleteQuiz',
    async (ids: { id: string, courseId: string, moduleId: string, lessonId: string, quizId: string }, thunkApi) => {
        try {
            const info = await deleteQuiz(ids.id, ids.courseId, ids.moduleId, ids.lessonId, ids.quizId);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const addIntroVideoThunk = createAsyncThunk(
    'programs/addIntroVideo',
    async (data: { id: string, introVideo: File }, thunkApi) => {
        try {
            const info = await addTheIntroVideo(data, (progress) => {
            });
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error)
        }
    }

)
export const publishTPThunk = createAsyncThunk(
    'program/publish',
    async (id: string, thunkApi) => {
        try {
            const info = await publishTP(id);
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const fetchCategoriesThunk = createAsyncThunk(
    'categories/fetch',
    async (_, thunkApi) => {
        try {
            const info = await getCategories();
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
)

export const fetchTagsThunk = createAsyncThunk(
    'categories/fetch',
    async (_, thunkApi) => {
        try {
            const info = await getTags();
            return info.data;
        } catch (e) {
            const error = retrieveErrorResponse<ApiException>(e);
            return thunkApi.rejectWithValue(error);
        }
    }
)

// Slice
export const currentTrainingProgramDataSlice = createSlice({
    name: 'trainingProgram',
    initialState,
    reducers: {
        setUploadProgress: (state, action) => {
            state.uploadProgress = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(createTP01.pending, (state) => {
                state.loading = true;
                state.uploadProgress = 0;
            })
            .addCase(createTP01.fulfilled, (state, action) => {
                state.loading = false;
                state.payload =  action.payload;
                state.uploadProgress = 100;
            })
            .addCase(createTP01.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
                state.uploadProgress = 0;
            })
            .addCase(createTP02.pending, (state) => {
                state.loading = true;
                state.uploadProgress = 0;
            })
            .addCase(createTP02.fulfilled, (state, action) => {
                state.loading = false;
                state.payload = action.payload;
                state.uploadProgress = 100;
            })
            .addCase(createTP02.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
                state.uploadProgress = 0;
            })
            .addCase(getATPCourses.pending, (state) => {
                state.loading = true;
            })
            .addCase(getATPCourses.fulfilled, (state, action) => {
                state.loading = false;
                // state.payload = { courses: action.payload };
            })
            .addCase(getATPCourses.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(getSingleProgram.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleProgram.fulfilled, (state, action) => {
                state.loading = false;
                state.payload = action.payload;
            })
            .addCase(getSingleProgram.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(updateTP.pending, (state) => {
                state.loading = true;
                state.uploadProgress = 0;
            })
            .addCase(updateTP.fulfilled, (state, action) => {
                state.loading = false;
                state.payload = action.payload;
                state.uploadProgress = 100;
            })
            .addCase(updateTP.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
                state.uploadProgress = 0;
            })
            .addCase(deleteTP.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteTP.fulfilled, (state, action) => {
                state.loading = false;
                state.payload = action.payload;
            })
            .addCase(deleteTP.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(getSingleCourse.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleCourse.fulfilled, (state, action) => {
                state.loading = false;
                // state.payload = { course: action.payload };
            })
            .addCase(getSingleCourse.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(updateCourseThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCourseThunk.fulfilled, (state, action) => {
                state.loading = false;
                // state.payload = { course: action.payload };
            })
            .addCase(updateCourseThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(deleteCourseThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteCourseThunk.fulfilled, (state, action) => {
                state.loading = false;
                // state.payload = { course: action.payload };
            })
            .addCase(deleteCourseThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(addModuleThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(addModuleThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.payload = action.payload
                //state.payload = { module: action.payload };
            })
            .addCase(addModuleThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(getModulesThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(getModulesThunk.fulfilled, (state, action) => {
                state.loading = false;
                //state.payload = { modules: action.payload };
            })
            .addCase(getModulesThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(getSingleModule.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleModule.fulfilled, (state, action) => {
                state.loading = false;
                //state.payload = { module: action.payload };
            })
            .addCase(getSingleModule.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(updateModuleThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateModuleThunk.fulfilled, (state, action) => {
                state.loading = false;
                //state.payload = { module: action.payload };
            })
            .addCase(updateModuleThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(deleteModuleThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteModuleThunk.fulfilled, (state, action) => {
                state.loading = false;
                //state.payload = { module: action.payload };
            })
            .addCase(deleteModuleThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(addLessonThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(addLessonThunk.fulfilled, (state, action) => {
                state.loading = false;
                [state.payload] = action.payload;
                //state.payload = { lesson: action.payload };
            })
            .addCase(addLessonThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(getLessonsThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(getLessonsThunk.fulfilled, (state, action) => {
                state.loading = false;
                //state.payload = { lessons: action.payload };
            })
            .addCase(getLessonsThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(getSingleLesson.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleLesson.fulfilled, (state, action) => {
                state.loading = false;
                //state.payload = { lesson: action.payload };
            })
            .addCase(getSingleLesson.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(updateLessonThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateLessonThunk.fulfilled, (state, action) => {
                state.loading = false;
                //state.payload = { lesson: action.payload };
            })
            .addCase(updateLessonThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(deleteLessonThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteLessonThunk.fulfilled, (state, action) => {
                state.loading = false;
                //state.payload = { lesson: action.payload };
            })
            .addCase(deleteLessonThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(addQuizThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(addQuizThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.payload = action.payload;
                //state.payload = { quiz: action.payload };
            })
            .addCase(addQuizThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(getQuizzesThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(getQuizzesThunk.fulfilled, (state, action) => {
                state.loading = false;
                // state.payload = { quizzes: action.payload };
            })
            .addCase(getQuizzesThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(getSingleQuiz.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleQuiz.fulfilled, (state, action) => {
                state.loading = false;
                // state.payload = { quiz: action.payload };
            })
            .addCase(getSingleQuiz.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(updateQuizThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateQuizThunk.fulfilled, (state, action) => {
                state.loading = false;
                // state.payload = { quiz: action.payload };
            })
            .addCase(updateQuizThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(deleteQuizThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteQuizThunk.fulfilled, (state, action) => {
                state.loading = false;
                // state.payload = { quiz: action.payload };
            })
            .addCase(deleteQuizThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(addIntroVideoThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(addIntroVideoThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(addIntroVideoThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.payload = action.payload;
                //state.payload = { quiz: action.payload };
            })
            // 
            .addCase(publishTPThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = [action.payload as ApiException];
            })
            .addCase(publishTPThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(publishTPThunk.fulfilled, (state, action) => {
                state.loading = false;
            })
    },
});




// Export actions
export const currentProgramActions = {
    createTP01,
    createTP02,
    publishTPThunk,
    getATPCourses,
    getSingleProgram,
    updateTP,
    deleteTP,
    addModuleThunk,
    getModulesThunk,
    getSingleModule,
    updateModuleThunk,
    deleteModuleThunk,
    addLessonThunk,
    getLessonsThunk,
    getSingleLesson,
    updateLessonThunk,
    deleteLessonThunk,
    addQuizThunk,
    getQuizzesThunk,
    getSingleQuiz,
    updateQuizThunk,
    deleteQuizThunk,
    addIntroVideoThunk,
    fetchCategoriesThunk,
    fetchTagsThunk,
};
export const { setUploadProgress } = currentTrainingProgramDataSlice.actions;
// Export the reducer function
export default currentTrainingProgramDataSlice.reducer;
