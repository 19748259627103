import { RouteObject } from "react-router-dom";
import { PATH_NAMES } from "./path-names";
import { pages } from "./definitions";
import { AuthGuard } from "./route-guard";
import AuthLayout from "../layouts/AuthLayout";
import DashboardLayout from "../layouts/DashboardLayout";

export const routes: Array<RouteObject> = [
  {
    path: PATH_NAMES.ROOT,
    element: <pages.Landing />,
  },
  {
    path: PATH_NAMES.ABOUT,
    element: <pages.About />
  },
  {
    path: PATH_NAMES.DATA_HANDLING,
    element: <pages.OurDataHandlingDetails />
  },
  {
    path: PATH_NAMES.CONTACT_US,
    element: <pages.ContactUs />,
  },
  {
    path: PATH_NAMES.FAQ,
    element: <pages.FAQ />,
  },
  {
    path: PATH_NAMES.INSTITUTIONS,
    element: <pages.Institutions />,
  },
  {
    path: PATH_NAMES.SIGN_UP,
    element: (
      <AuthLayout>
        <pages.SignUp />
      </AuthLayout>
    ),
  },
  {
    path: PATH_NAMES.SIGN_IN,
    element: (
      <AuthLayout>
        <pages.SignIn />
      </AuthLayout>
    ),
  },
  {
    path: PATH_NAMES.FORGOT_PASSWORD,
    element: (
      <AuthLayout>
        <pages.ForgotPassword />
      </AuthLayout>
    ),
  },
  {
    path: PATH_NAMES.VERIFY_OTP,
    element: (
      <AuthLayout>
        <pages.Otp />
      </AuthLayout>
    ),
  },
  {
    path: PATH_NAMES.ONBOARDING,
    element: (
      <AuthGuard guardWhat="onboarding" failTo={PATH_NAMES.SIGN_IN}>
        <pages.Onboarding />
      </AuthGuard>
    ),
    children: [
      { path: "photo", element: <pages.Photo /> },
      { path: "certification", element: <pages.Certification /> },
      { path: "social", element: <pages.Social /> },
      { path: "bio", element: <pages.Bio /> },
      { path: "review", element: <pages.Review /> },
    ],
  },
  {
    path: PATH_NAMES.DASHBOARD,
    element: (
      <AuthGuard failTo={PATH_NAMES.SIGN_IN}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      { path: "", element: <pages.Dashboard /> },
      { path: "communication", element: <pages.Communication /> },
      {
        path: "programs",
        children: [
          { path: "", element: <pages.MyProgram /> },
          {
            path: ":programId",
            element: <pages.ProgramDetail />,
            children: [
              {
                path: ":courseId/:lessonId",
                element: <pages.LessonDetail />,
              },
              
            ],
          },
          {
            path: ":programId/edit",
            element: <pages.EditProgram />,
          },
          {
            path: "create-program",
            element: <pages.CreateTPLayout />,
            children: [
              { path: "", element: <pages.CreateTPForm intent="create" /> },
              // { path: "program-duration", element: <pages.ProgramDuration /> },
              { path: "course", element: <pages.AddCourses /> },
              { path: "courses-module", element: <pages.AddModules /> },
              { path: "courses-lesson", element: <pages.AddLessons /> },
              { path: "intro-video", element: <pages.AddIntroVideo /> },
              { path: "preview-program", element: <pages.PreviewProgram /> },
              {
                path: "save-and-publish-program",
                element: <pages.SaveAndPublishProgram />,
              },
            ],
          },
        ],
      },
      { path: "performance", element: <pages.Performance /> },
      {
        path: "settings",
        children: [
          {
            path: "account",
            element: <pages.Account />,
            children: [
              { path: "", element: <pages.AccountSecurity /> },
              { path: "security", element: <pages.AccountSecurity /> },
              { path: "messages", element: <pages.MessageSettings /> },
              {
                path: "notifications",
                element: <pages.NotificationsSettings />,
              },
              { path: "close-account", element: <pages.CloseAccount /> },
            ],
          },
          { path: "", element: <pages.Profile /> },
        ],
      },
      { path: "support", element: <pages.Support /> },
    ],
  },
  {
    path: PATH_NAMES.NOT_FOUND,
    element: <pages.NotFound />,
  },
];
