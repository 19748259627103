import { AxiosProgressEvent } from "axios";
import { Course, Lesson, LessonsState, Module, Quiz, TrainingProgram, Tutor, createTPData01, createTPData02, enteredLessonData, enteredModuleData, enteredQuizData, lessonGroup, updateTrainingProgramType } from "../../types";
import { PrincipalResponse } from "../types";
import { backend, endpoints } from "./config.api";

// _____
/**C
 * The first step in creating a training program
 * @param data of type createTPData02
 * @returns a Promise Response
 */
export const createATP01 = async (
  data: createTPData01,
  onUploadProgressCallback: (progress: number) => void
): Promise<Partial<TrainingProgram>> => {

  const formData = new FormData();

  Object.entries(data).forEach(([k, v]) => {
    if (v instanceof File) {
      formData.append(k, v);
    } else if (Array.isArray(v)) {
      formData.append(k, JSON.stringify(v));
    } else if (k === 'price') {
      formData.append(k, JSON.stringify({ currency: 'XAF', amount: String(v) }));
    } else {
      formData.append(k, String(v));
    }
  });


  const createTPCall = await backend.post<Partial<TrainingProgram>>(endpoints.tutors.createProgram, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent && progressEvent.total) {
        const percentCompleted = Math.round((progressEvent.loaded * 95) / progressEvent.total);
        onUploadProgressCallback(percentCompleted);
      }
    },
  });

  return createTPCall.data
}

/**R
 * Reads all the programs
 * @returns All Training Programs
 */
export const getPrograms = (token: string) =>
  backend.get<PrincipalResponse['trainingProgram']>(endpoints.tutors.getProgram, {
    headers: {
      Authorization: `Bearer ${token}`
    },

  });
/**
 * Get a Single Training Program
 * @param id 
 * @returns A Single Training Program
 */
export const getSingleTrainingProgram = (id: string) =>
  backend.get<Partial<TrainingProgram>>(endpoints.tutors.getATrainingProgram(id));

/**
 * Update a Training program
 * @param  id
 * @returns The updated training Program
 */
export const updateTrainingProgram = (data: updateTrainingProgramType, onUploadProgressCallback: (progress: number) => void) => {
  const formData = new FormData();
  Object.entries(data.trainingProgram).forEach(([k, v]) => {
    if (v instanceof File) {
      formData.append(k, v)
    } else if (typeof v !== 'object') {
      formData.append(k, String(v))
    }
  })
  return backend.patch<Partial<TrainingProgram>>(endpoints.tutors.updateTrainingProgram(data.id), {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent && progressEvent.total) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onUploadProgressCallback(percentCompleted);
      }
    },
  })
}
/**
 * Delete a Training Program
 * @param id
 * @returns The deleted Training Program
 */
export const deleteTrainingProgram = (id: string) =>
  backend.delete<Partial<TrainingProgram>>(endpoints.tutors.updateTrainingProgram(id))

// _______
/**
 * Add course
 * @param data of type createTPData02
 * @returns a Promise Response
 */
export const createATP02 = (data: createTPData02, onUploadProgressCallback: (progress: number) => void) => {
  const formData = new FormData();
  formData.append('title', data.title);
  formData.append('description', data.description);
  if (data.image)
    formData.append('image', data.image);
  return backend.patch<Partial<TrainingProgram>>(endpoints.tutors.addCourse(data.currentTPId), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent && progressEvent.total) {
        const percentCompleted = Math.round((progressEvent.loaded * 95) / progressEvent.total);
        onUploadProgressCallback(percentCompleted);
      }
    },
  });
}
/**
 * Get all Courses for a TrainingProgram
 * @param id
 * @returns Array of courses
 */
export const getCourses = (id: string) =>
  backend.get<Course[]>(endpoints.tutors.addCourse(id))
/**
 * Get a Single Course
 * @param id
 * @param courseId
 * @returns A Course object
 */
export const getACourse = (id: string, courseId: string) =>
  backend.get<Course>(endpoints.tutors.getACourse(id, courseId))
/**
 * Update a Course
 * @param partialCourseObject
 * @param id
 * @param courseId
 * @returns updated course object
 */

export const updateCourse = (partialCourseObject: Partial<Course>, id: string, courseId: string) => {
  const formData = new FormData();
  Object.entries(partialCourseObject).forEach(([k, v]) => {
    if (v instanceof File) {
      formData.append(k, v)
    } else if (typeof v !== 'object') {
      formData.append(k, String(v))
    }
  })

  return backend.patch<Course>(endpoints.tutors.getACourse(id, courseId), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  })
}

/**
 * Delete a course
 * @param id
 * @param courseId
 * @returns deleted course object
 */
export const deleteCourse = (id: string, courseId: string) =>
  backend.delete<Course>(endpoints.tutors.getACourse(id, courseId))

// _____
/**
 * Create/Add a Module
 * @param data entered module Data
 * @param id
 * @param courseId
 * @returns The Added Module
 */
export const addModule = (data: enteredModuleData, id: string, courseId: string, onUploadProgressCallback: (progress: number) => void) =>
  backend.patch<Partial<TrainingProgram>>(endpoints.tutors.addModules(id, courseId), data, {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent && progressEvent.total) {
        const percentCompleted = Math.round((progressEvent.loaded * 95) / progressEvent.total);
        onUploadProgressCallback(percentCompleted);
      }
    },
  })

/**
 * Get all Modules
 * @param id
 * @param courseId
 * @returns All module Objects of a given course
 */
export const getModules = (id: string, courseId: string) =>
  backend.get<Module>(endpoints.tutors.addModules(id, courseId))

/**
 * Get a Module
 * @param id
 * @param courseId
 * @param moduleId
 * @returns A specific module Object
 */
export const getAModule = (id: string, courseId: string, moduleId: string) =>
  backend.get<Module>(endpoints.tutors.getAModule(id, courseId, moduleId))

/**
 * Update a Module
 * @param data used to update the Module
 * @param id
 * @param courseId
 * @param moduleId
 * @returns updated module object
 */
export const updateModule = (data: Partial<enteredModuleData>, id: string, courseId: string, moduleId: string) =>
  backend.patch<Module>(endpoints.tutors.getAModule(id, courseId, moduleId), data)

/**
 * Delete a Module
 * @param id
 * @param courseId
 * @param moduleId
 * @returns The Deleted module Object
 */
export const deleteModule = (id: string, courseId: string, moduleId: string) =>
  backend.delete<Module>(endpoints.tutors.getAModule(id, courseId, moduleId))

/**
 * Add Lesson
 * @param data of type LessonsState
 * @param id TP (Training Program) id
 * @param onUploadProgressCallback Callback function to track upload progress
 * @returns a Promise that resolves to a Partial TrainingProgram response
 */
export const addLesson = async (
  data: LessonsState,
  id: string,
  onUploadProgressCallback: (progress: number) => void
): Promise<Partial<TrainingProgram>[]> => {

  const responses: Partial<TrainingProgram>[] = [];

  // Loop through each course
  for (const courseId in data) {
    const modules = data[courseId];

    // Loop through each module in the course
    for (const moduleId in modules) {
      const lesson = modules[moduleId];

      const formData = new FormData();
      // formData.append('courseId', courseId);  // Send course ID
      // formData.append('moduleId', moduleId);  // Send module ID

      formData.append('title', lesson.title);

      // Handle notes (if exists)
      if (lesson.notes) {
        formData.append('notes', lesson.notes);
      }

      // Handle video file (if exists)
      if (lesson.video) {
        formData.append('video', lesson.video);
      }

      // Handle attachments (if any)
      if (lesson.attachments && lesson.attachments.length > 0) {
        lesson.attachments.forEach((file, index) => {
          formData.append(`attachments`, file);
        });
      }

      // Handle quizzes
      lesson.quizzes.forEach((quiz, quizIndex) => {
        formData.append(`quizzes[${quizIndex}][question]`, quiz.question);
        quiz.possibleAnswers.forEach((answer, answerIndex) => {
          formData.append(`quizzes[${quizIndex}][possibleAnswers][${answerIndex}]`, answer);
        });
        formData.append(`quizzes[${quizIndex}][answer]`, quiz.answer);
      });

      try {
        const createLessonResponse = await backend.patch<Partial<TrainingProgram>>(
          endpoints.tutors.addLessons(id, courseId, moduleId),
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
              if (progressEvent && progressEvent.total) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                onUploadProgressCallback(percentCompleted);
              }
            },
          }
        );

        // Store the response of each lesson update
        responses.push(createLessonResponse.data);
      } catch (error) {
        console.error(`Error updating lesson for module ${moduleId} in course ${courseId}:`, error);
        throw error;
      }
    }
  }

  // Return the array of responses for all lessons
  return responses;
};

// export const addLesson = async (data: enteredLessonData, id: string, courseId: string, moduleId: string, onUploadProgressCallback: (progress: number) => void): Promise<Partial<TrainingProgram>> => {

//   const chunkSize = 5 * 1024 * 1024; // 5MB

//   // Step 1: Create training program
//   const createLessonResponse = await backend.patch<Partial<TrainingProgram>>(
//     endpoints.tutors.addLessons(id, courseId, moduleId),
//     {
//       title: data.title,
//       description: data.description,
//       notes: data.notes
//     }
//   );

//   const lessonId = createLessonResponse.data._id;


//   if (data.video && typeof data.video === "object" && lessonId) {
//     const videoFile = data.video as File;

//     // Step 2: Initialize upload
//     const initResponse = await backend.post(endpoints.tutors.initializeUpload(lessonId), {
//       fileName: videoFile.name,
//     });

//     const uploadId = initResponse.data.uploadId;
//     const totalSize = videoFile.size;

//     // Step 3: Upload chunks
//     const totalChunks = Math.ceil(totalSize / chunkSize);
//     const uploadPromises = [];

//     for (let i = 0; i < totalChunks; i++) {
//       const start = i * chunkSize;
//       const end = Math.min(start + chunkSize, totalSize);
//       const chunk = videoFile.slice(start, end);

//       const chunkFormData = new FormData();
//       chunkFormData.append('chunk', chunk);
//       chunkFormData.append('chunkNumber', String(i));
//       chunkFormData.append('uploadId', uploadId);

//       const uploadPromise = backend.post(
//         endpoints.tutors.uploadChunk(lessonId),
//         chunkFormData,
//         {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//           onUploadProgress: (progressEvent: AxiosProgressEvent) => {
//             if (progressEvent && progressEvent.total) {
//               const percentCompleted = Math.round(
//                 (progressEvent.loaded * 100) / progressEvent.total
//               );
//               const overallProgress = Math.round(((i + percentCompleted / 100) * 100) / totalChunks);
//               onUploadProgressCallback(overallProgress);
//             }
//           },
//         }
//       );

//       uploadPromises.push(uploadPromise);
//     }

//     await Promise.all(uploadPromises);
//   }

//   if (lessonId) {
//     // Step 4: Finalize upload
//     const finalizeResponse = await backend.post(endpoints.tutors.finalizeUpload(lessonId));
//     // const fileUrl = finalizeResponse.data.fileUrl;
//     console.log(finalizeResponse)
//     return finalizeResponse.data;
//   }

//   return createLessonResponse.data;

// }
/**
 * Get all Lessons for a Module
 * @param id
 * @param courseId 
 * @param moduleId
 * @returns Array of Lessons
 */
export const getLessons = (id: string, courseId: string, moduleId: string) =>
  backend.get<Lesson[]>(endpoints.tutors.getLessons(id, courseId, moduleId))

/**
 * Get a Single Lesson
 * @param id
 * @param courseId
 * @param moduleId
 * @param lessonId
 * @returns A lesson object
 */
export const getALesson = (id: string, courseId: string, moduleId: string, lessonId: string) =>
  backend.get<Lesson>(endpoints.tutors.getALesson(id, courseId, moduleId, lessonId))
/**
 * Update a Lesson
 * @param partialLessonObject
 * @param id
 * @param courseId
 * @param moduleId
 * @param lessonId
 * @returns updated lesson object
 */

export const updateLesson = (partialLessonObject: Partial<enteredLessonData>, id: string, courseId: string, moduleId: string, lessonId: string) => {
  // const formData = new FormData();
  // Object.entries(partialLessonObject).forEach(([k, v]) => {
  //   if (v instanceof File) {
  //     formData.append(k, v)
  //   } else if (typeof v !== 'object') {
  //     formData.append(k, String(v))
  //   }
  // })

  return backend.patch<Lesson>(endpoints.tutors.updateLesson(id, courseId, moduleId, lessonId), partialLessonObject, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  })
}

/**
 * Delete a Lesson
 * @param id
 * @param courseId
 * @param moduleId
 * @param lessonId
 * @returns deleted lesson object
 */
export const deleteLesson = (id: string, courseId: string, moduleId: string, lessonId: string) =>
  backend.delete<Lesson>(endpoints.tutors.deleteLesson(id, courseId, moduleId, lessonId))

// ____
/**
 * Create/Add a Quiz
 * @param data entered Quiz Data
 * @param id
 * @param courseId
 * @param moduleId
 * @param lessonId
 * @returns The Added Quiz
 */
export const addQuiz = (data: enteredQuizData, id: string, courseId: string, moduleId: string, lessonId: string, onUploadProgressCallback: (progress: number) => void) =>
  backend.patch<Partial<TrainingProgram>>(endpoints.tutors.addQuizzes(id, courseId, moduleId, lessonId), data, {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent && progressEvent.total) {
        const percentCompleted = Math.round((progressEvent.loaded * 95) / progressEvent.total);
        onUploadProgressCallback(percentCompleted);
      }
    }
  })

/**
 * Get all Quizzes
 * @param id
 * @param courseId
 * @param moduleId
 * @param lessonId
 * @returns All Quiz Objects of a given Lesson
 */
export const getQuizzes = (id: string, courseId: string, moduleId: string, lessonId: string) =>
  backend.get<Quiz>(endpoints.tutors.addQuizzes(id, courseId, moduleId, lessonId))

/**
 * Get a Quiz
 * @param id
 * @param courseId
 * @param moduleId
 * @param lessonId
 * @param quizId
 * @returns A specific module Object
 */
export const getAQuiz = (id: string, courseId: string, moduleId: string, lessonId: string, quizId: string) =>
  backend.get<Module>(endpoints.tutors.getAQuiz(id, courseId, moduleId, lessonId, quizId))

/**
 * Update a Quiz
 * @param data used to update the Quiz
 * @param id
 * @param courseId
 * @param moduleId
 * @param lessonId
 * @param quizId
 * @returns updated quiz object
 */
export const updateQuiz = (data: Partial<enteredQuizData>, id: string, courseId: string, moduleId: string, lessonId: string, quizId: string) =>
  backend.patch<Module>(endpoints.tutors.getAQuiz(id, courseId, moduleId, lessonId, quizId), data)

/**
 * Delete a Quiz
 * @param id
 * @param courseId
 * @param moduleId
 * @param lessonId
 * @param quizId
 * @returns The Deleted quiz Object
 */
export const deleteQuiz = (id: string, courseId: string, moduleId: string, lessonId: string, quizId: string) =>
  backend.delete<Module>(endpoints.tutors.getAQuiz(id, courseId, moduleId, lessonId, quizId))

/**
 * Add the Intro Video
 * @param data (tp Id and video)
 * @returns The updated tp Object
 */
export const addTheIntroVideo = (data: { id: string, introVideo: File }, onUploadProgress?: (progress: number) => void) => {
  const formData = new FormData();
  formData.append('video', data.introVideo);
  return backend.patch<Partial<TrainingProgram>>(endpoints.tutors.addTheIntroVid(data.id), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (event) => {
      const total = event.total ?? 1;
      if (onUploadProgress) {
        const percentCompleted = Math.round((event.loaded * 100) / total);
        onUploadProgress(percentCompleted);
      }
    },
  });


}

export const getTutorsForProgram = (data: {
  tutorId: string;
  role: string;
}[]) => backend.post<{ tutor: Tutor; role: string; }[]>(endpoints.tutors.getTutorsForProgram, data)

export const publishTP = async (id: string) => {

  try {
    const response = await backend.patch<{ message: string, program: Partial<TrainingProgram> }>(
      endpoints.tutors.publishTrainingProgram(id)
    );
    return response;
  } catch (error) {
    console.error("Failed to publish training program", error);
    throw error;
  }
};


export const getCategories = () =>
  backend.get<string[]>(endpoints.global.listCategories)

export const getTags = () =>
  backend.get<string[]>(endpoints.global.listTags)

/**R
 * Subscribe to the newsletter
 * @returns A Message of Subscription
 */
export const subscribeToNewsletter = (email: string) =>
  backend.post<string>(endpoints.global.subscribeToNewsLetter, { email: email });