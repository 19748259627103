import {AxiosError} from "axios";
import {ApiError, ApiException} from "../types";
import {TrainingProgram} from "../../types";

export interface AsyncState<T, E = unknown> {
    payload?: T;
    loading: boolean;
    errors?: E[];
}

export interface AsyncStateTP<T, E = unknown> {
    payload?: T;
    uploadProgress: number;
    loading: boolean;
    errors?: E[];
}

export const retrieveErrorResponse = <
    T extends ApiException | ApiError[] = ApiException
>(
    e: unknown
): T | undefined => {
    if (isAxiosError<T>(e)) {
        return e?.response?.data as T;
    }
};

export const isAxiosError = <T>(e: unknown): e is AxiosError<T> => {
    return (e as AxiosError<T>).isAxiosError;
};

export const isValidFileSize = (file: File): boolean => {
    const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
    return file.size <= maxSize;
};

export const isValidFileType = (file: File): boolean => {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    return allowedTypes.includes(file.type);
};

export const currentPathName = () => window?.location?.pathname;

export const currentSearchParams = () =>
    new URLSearchParams(window?.location?.search);

export const slotText = (text: string) =>
    text.split(" ").join("-").toLowerCase();

export function formatDate(date: string | Date): string {
    const dateObject = typeof date === 'string' ? new Date(date) : date;
    const month = dateObject.toLocaleString('default', {month: 'short'});
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();
    return `${month} ${day}, ${year}`

}

// Type guard for File
export function isFile(value: any): value is File {
    return value instanceof File;
}

// Type guard for Blob
export function isBlob(value: any): value is Blob {
    return value instanceof Blob;
}

/**
 * Checks if the input string contains any special characters.
 * @param input - The string to check.
 * @returns True if the string contains special characters, false otherwise.
 */
export const hasSpecialCharacters = (input: string): boolean => {
    const specialChars = /[!@#$%^&*(),.?":{}|<>]/g;
    const urlUnsafeChars = /[^a-zA-Z0-9-._~ ]/g;
    
    return specialChars.test(input) || urlUnsafeChars.test(input);
};



/**
 * Checks if all properties of an object are filled.
 * @param form - The object to check.
 * @returns True if all properties are filled, false otherwise.
 */
export const isFormComplete = (form: Record<string, any>): boolean => {
    for (const key in form) {
        const value = form[key];
        if (value === '' || value === null || value === undefined || (typeof value === 'number' && value < 0)) {
            return false;
        }
    }
    return true;
};

/**
 * Checks if all string provided is an email
 * @param email - The string to check.
 * @returns `True` if String is Email.
 */
export const isEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const scrollToBottom = (duration: number = 2000): void => {
    const start = window.scrollY;
    const end = document.body.scrollHeight - window.innerHeight;
    const change = end - start;
    let startTime: number | null = null;

    const animateScroll = (currentTime: number) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = easeInOutQuad(timeElapsed, start, change, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) requestAnimationFrame(animateScroll);
    };

    const easeInOutQuad = (t: number, b: number, c: number, d: number): number => {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animateScroll);
};


export const urlToFile = async (url: string, filename: string, mimeType: string): Promise<File> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, {type: mimeType});
};


export const getNumberOfLessons = (trainingProgram: TrainingProgram | undefined): number => {
    if (trainingProgram) {
        return trainingProgram.courses.reduce((courseCount, course) => {
            return courseCount + course.modules.reduce((moduleCount, module) => {
                return moduleCount + module.lessons.length;
            }, 0);
        }, 0);
    } else {
        return 0
    }
};

export const getNumberOfReviews = (trainingProgram: TrainingProgram | undefined): number => {
    if (trainingProgram) {
        return trainingProgram.data?.reviews.length!
    } else {
        return 0
    }
}

export const getNumberOfStudents = (trainingProgram: TrainingProgram | undefined): number => {
    if (trainingProgram) {
        return trainingProgram.data?.numOfStudents!
    } else {
        return 0
    }
}

export function stringToBoolean(str: 'true' | 'false') {
    if (str)
        return str.toLowerCase() === "true";
    else {
        return false
    }
}

export const hashValue = async (value: string): Promise<string> => {
    const encoder = new TextEncoder();
    const data = encoder.encode(value); // Convert string to Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-256", data); // Hash the data
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join(""); // Convert byte array to hex string
    return hashHex;
  };
  