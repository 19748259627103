import { Box, Flex, Grid, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";

type Props = {};

const collaborators = [
  {
    name: "Google",
    logo: "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
  },
  {
    name: "Facebook",
    logo: "https://www.facebook.com/images/fb_icon_325x325.png",
  },
  {
    name: "Amazon",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/1024px-Amazon_logo.svg.png",
  },
  {
    name: "Microsoft",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/1024px-Microsoft_logo.svg.png",
  },
  {
    name: "Apple",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/1024px-Apple_logo_black.svg.png",
  },
  {
    name: "Netflix",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Netflix_2015_logo.svg/1024px-Netflix_2015_logo.svg.png",
  },
  {
    name: "Tesla",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Tesla_Motors.svg/1024px-Tesla_Motors.svg.png",
  },
  //   {
  //     name: "Twitter",
  //     logo: "https://upload.wikimedia.org/wikipedia/fr/thumb/c/c8/Twitter_Bird.svg/1024px-Twitter_Bird.svg.png",
  //   },
];

const Collaborators = (props: Props) => {
  return (
    <Flex
      
      p="clamp(36px, -23.2258064516px + 13.935483871vw, 144px);"
      w="100%"
      h="80vh"
      justifyContent="center"
      alignItems="center"
      bg="linear-gradient(#000, rgba(7, 16, 55, .75))"
      backdropFilter="blur(36px)"
      color="white"
    >
      
      {/* <Flex
        justifyContent={"center"}
        align={"center"}
        gap={{
          base: "1em",
          md: "2em",
        }}
        overflow={{
          base: "scroll",
          md: "hidden",
        }}
        my={{
          base: "2em",
          md: "4em",
        }}
      >
        {collaborators.map((collaborator) => (
          <Box>
            <Image
              src={collaborator.logo}
              alt={collaborator.name}
              width={{
                base: "50px",
                md: "75px",
                lg: "100px",
              }}
              //   height="100px"
            />
          </Box>
        ))}
        
      </Flex> */}
      <Flex flexFlow="wrap" justifyContent={"space-around"} gap="clamp(24px, 10.8387096774px + 3.0967741935vw, 48px)">
                {collaborators.map((collaborator, index) => {
                    return (
                        <Image
                            overflow="clip"
                            key={index}
                            src={collaborator.logo}
                            alt={`image-${index}`}
                            h="fit-content"
                            w={{
                              base: "50px",
                              md: "75px",
                              lg: "100px",
                            }}
                        />
                    );
                })}
            </Flex>
    </Flex>
  );
};

export default Collaborators;
