const dbName = 'fileStorageDB';
const storeName = 'files';
const indexName = 'idIndex';

const openDB = () => {
    return new Promise<IDBDatabase>((resolve, reject) => {
        const request = indexedDB.open(dbName, 1);

        request.onupgradeneeded = () => {
            const db = request.result;
            const store = db.createObjectStore(storeName, { keyPath: 'id' });
            store.createIndex(indexName, 'id'); // Create an index on the 'id' property
        };

        request.onsuccess = () => resolve(request.result);
        request.onerror = () => {
            console.error("Failed to open the database", request.error);
            reject(request.error);
        };
    });
};

export const saveFile = async (id: string, file: Blob) => {
    const db = await openDB();
    return new Promise<void>((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);
        const request = store.put({ id, file });

        transaction.oncomplete = () => resolve();
        transaction.onerror = () => {
            console.error("Transaction failed", transaction.error);
            reject(transaction.error);
        };
        request.onerror = () => {
            console.error("Request failed", request.error);
            reject(request.error);
        };
    });
};

export const getFile = async (id: string): Promise<Blob | undefined> => {
    const db = await openDB();
    return new Promise<Blob | undefined>((resolve, reject) => {
        const transaction = db.transaction(storeName);
        const store = transaction.objectStore(storeName);
        const request = store.get(id);

        request.onsuccess = () => resolve(request.result?.file);
        request.onerror = () => {
            console.error("Request failed", request.error);
            reject(request.error);
        };
    });
};

export const getAllFiles = async (): Promise<{ id: string; file: Blob }[]> => {
    const db = await openDB();
    return new Promise<{ id: string; file: Blob }[]>((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readonly');
        const store = transaction.objectStore(storeName);
        const request = store.getAll();

        request.onsuccess = () => {
            const result = request.result.map((item: { id: string; file: Blob }) => ({
                id: item.id,
                file: item.file,
            }));
            resolve(result);
        };
        request.onerror = () => {
            console.error("Request failed", request.error);
            reject(request.error);
        };
    });
};

export const removeFile = async (id: string) => {
    const db = await openDB();
    return new Promise<void>((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);
        const request = store.delete(id);

        transaction.oncomplete = () => resolve();
        transaction.onerror = () => {
            console.error("Transaction failed", transaction.error);
            reject(transaction.error);
        };
        request.onerror = () => {
            console.error("Request failed", request.error);
            reject(request.error);
        };
    });
};

export const deleteDatabase = () => {
    return new Promise<void>((resolve, reject) => {
        const request = indexedDB.deleteDatabase(dbName);

        request.onsuccess = () => {
            console.log(`Database ${dbName} deleted successfully`);
            resolve();
        };
        request.onerror = () => {
            console.error("Failed to delete the database", request.error);
            reject(request.error);
        };
        request.onblocked = () => {
            console.warn("Database deletion blocked");
            reject(new Error("Database deletion blocked"));
        };
    });
};

export const getFilesStartingWith = async (prefix: string): Promise<{ id: string; file: Blob }[]> => {
    const db = await openDB();
    return new Promise<{ id: string; file: Blob }[]>((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readonly');
        const store = transaction.objectStore(storeName);
        const index = store.index(indexName);
        const range = IDBKeyRange.bound(prefix, prefix + '\uffff'); // Range for IDs starting with the prefix
        const request = index.openCursor(range);

        const result: { id: string; file: Blob }[] = [];
        request.onsuccess = () => {
            const cursor = request.result;
            if (cursor) {
                result.push({ id: cursor.value.id, file: cursor.value.file });
                cursor.continue();
            } else {
                resolve(result);
            }
        };
        request.onerror = () => {
            console.error("Request failed", request.error);
            reject(request.error);
        };
    });
};
