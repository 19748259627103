/* eslint-disable react-hooks/rules-of-hooks */
"use client";
import { Helmet } from "react-helmet-async";
import { Outlet, useNavigate, Link, useLocation } from "react-router-dom";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
} from "@chakra-ui/react";
import {
  FiHome,
  FiCompass,
  FiMenu,
  FiBell,
  FiChevronDown,
} from "react-icons/fi";
import { IconType } from "react-icons";
import darkOffice1 from "../../assets/images/darkOffice3.jpg";
import bench from "../../assets/images/bench.jpg";
import { FiMessageCircle, FiTrendingUp } from "react-icons/fi";
import { useState } from "react";
import { Logo } from "../../components/common/Logo";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { SignOut } from "../../components/Modal/Modal";
import { useAppDispatch } from "../../app/hooks";
import {
  setModalContent,
  toggleModal,
} from "../../store/features/slices/utitlitySlice";
// import authService from "../../services/auth.service";

export interface LinkItemProps {
  name: string;
  to: string;
  icon: IconType;
}

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: React.ReactNode;
}

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  linkItems?: LinkItemProps[];
}

export const LinkItems: LinkItemProps[] = [
  { name: "Home", icon: FiHome, to: "/userzone" },
  { name: "Performance", icon: FiTrendingUp, to: "/performance" },
  { name: "Communication", icon: FiMessageCircle, to: "/communication" },
  { name: "Learn how to", icon: FiCompass, to: "/learn-about-creation" },
];

export const SidebarContent = ({
  onClose,
  linkItems,
  ...rest
}: SidebarProps) => {
  const location = useLocation();

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Logo />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {linkItems &&
        linkItems.map((link) => (
          <NavItem
            to={link.to}
            key={link.name}
            icon={link.icon}
            isActive={location.pathname === link.to}
          >
            {link.name}
          </NavItem>
        ))}
      {LinkItems.map((link) => (
        <NavItem
          to={link.to}
          key={link.name}
          icon={link.icon}
          isActive={location.pathname === link.to}
        >
          {link.name}
        </NavItem>
      ))}
      <Box
        h={{ base: "70%", md: "75%" }}
        display="flex"
        alignItems="end"
        justifyContent="start"
      >
        <ColorModeSwitcher />
      </Box>
    </Box>
  );
};

export const NavItem = ({
  icon,
  children,
  to,
  isActive,
  ...rest
}: NavItemProps & { to: string; isActive: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [selected, setSelected] = useState(false);
  const handleClick = () => {
    navigate(to);
    if (pathname === to) {
      setSelected(true);
    }
    setSelected(false);
    
  };

  return (
    <Box style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
      <Flex
        align="center"
        as={Link}
        to={to}
        sx={
          isActive
            ? {
                bg: "#1944ab",
                color: "white",
              }
            : { bg: "unset", color: "inherit" }
        }
        p="4"
        mx="4"
        fontFamily="Montserrat"
        fontSize="sm"
        borderRadius="lg"
        border="1px solid transparent"
        textAlign="left"
        role="group"
        cursor="pointer"
        _hover={{
          bg: isActive ? "#1944ab" : useColorModeValue("gray.50", "gray.700"),
          // border: "1px solid gainsboro"
        }}
        onClick={handleClick} // Add this line
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: useColorModeValue("inherit", "inherit"),
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const dispatch = useAppDispatch();

  const handleSignOut = () => {
    dispatch(toggleModal(true));
    dispatch(
      setModalContent({
        content: <SignOut />,
        modalState: true,
      })
    );
  };
  // const currentUser: user | null = authService.getCurrentUser();

  return (
    <Flex
      position="fixed"
      top="0"
      zIndex={3}
      w={{ base: "100%", sm: "75%", md: "80%", lg: "83.5%" }}
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Box display={{ base: "flex", md: "none" }}>
        <Logo />
      </Box>

      <HStack spacing={{ base: "0", md: "6" }}>
        <Popover>
          <PopoverTrigger>
            <IconButton
              size="lg"
              variant="ghost"
              aria-label="open menu"
              icon={<FiBell />}
            />
          </PopoverTrigger>
          <PopoverContent fontSize="sm">
            <PopoverArrow />
            <PopoverHeader
              fontWeight={600}
              as={Flex}
              justifyContent="space-between"
            >
              <Text fontSize="md">Notifications</Text>
              <Text as={Link} to={`account/notifications`} color="primary">
                Settings
              </Text>
            </PopoverHeader>
            <PopoverBody>No Notifications</PopoverBody>
          </PopoverContent>
        </Popover>

        <ColorModeSwitcher justifySelf="flex-end" />

        <Flex alignItems={"center"} fontFamily="Montserrat">
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar
                  size={"sm"}
                  src={
                    "https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                  }
                />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  {/* <Text fontSize="sm">{currentUser?.name}</Text> */}
                  <Text fontSize="xs" color="gray.600">
                    Tutor
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
              fontSize="sm"
            >
              <MenuItem as={Link} to="profile">
                Edit Profile
              </MenuItem>
              <MenuItem as={Link} to="account/me">
                Account
              </MenuItem>
              <MenuItem>Payout and Tax settings</MenuItem>
              <MenuDivider />
              <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const SidebarWithHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" w="100vw">
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box
        minH="100%"
        backgroundImage={useColorModeValue(bench, darkOffice1)}
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        ml={{ base: 0, md: 60 }}
        p="4"
        textAlign="left"
        pt="6rem"
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default function UserZone() {
  return (
    <>
      <Helmet>
        <title>Dashboard | Liahtutor</title>
        <meta
          name="description"
          content="Tutor Dashboard for Creating Training Programs"
        />
      </Helmet>
      <SidebarWithHeader />
    </>
  );
}
