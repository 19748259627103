import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import authSlice from "./features/slices/authSlice";
import trainingProgramDataSlice from "./features/slices/trainingPrograms";
import utilitySlice from "./features/slices/utitlitySlice";
import trainingProgramId from "./features/slices/trainingProgramId";
import middlewares from "./features/middware";
import currentTrainingProgramDataSlice from "./features/slices/currentTrainingProgram";
import newsLetterSlice from "./features/slices/newsletter";

// Root reducer combining all slices
const rootReducer = combineReducers({
  trainingProgram: trainingProgramDataSlice,
  newsletter: newsLetterSlice,
  currentTrainingProgram: currentTrainingProgramDataSlice,
  utility: utilitySlice,
  auth: authSlice,
  trainingProgramId: trainingProgramId,
});

// Redux persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: [], // Reducers listed here will be persisted
  predicate: (state: any, action: { type: string; }) => action.type.startsWith('persist/')
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store using the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (defaultMiddleware) =>
    defaultMiddleware().concat(middlewares),
});

// Create persistor
export const persistor = persistStore(store);

// Define the AppDispatch type
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
