import React, { useState } from 'react';
import { Image, Skeleton } from '@chakra-ui/react';

interface ImageWithFallbackProps {
    src: string;
    alt: string;
    style?: React.CSSProperties;
    width: {
        base: string, // Smaller width on mobile
        md: string, // Larger width on medium screens and above
    };
    height: string;
}

export const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({ src, alt, style, width, height }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    return (
        <Skeleton isLoaded={!isLoading} height={height} width={width}>
            {!hasError ? (
                <Image
                    src={src}
                    alt={alt}
                    style={style}
                    onLoad={() => setIsLoading(false)}
                    onError={() => setHasError(true)}
                    width={width}
                    height={height}
                    objectFit="cover"
                />
            ) : (
                <Image
                    src="/fallback-image.png" // Provide a fallback image URL
                    alt="fallback"
                    style={style}
                    width={width}
                    height={height}
                    objectFit="contain"
                />
            )}
        </Skeleton>
    );
};
