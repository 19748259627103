import { createListenerMiddleware, type AsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "../../../store";
import { currentPrincipalTPKey } from "../../../../constants";
import { currentProgramActions } from "../../slices/currentTrainingProgram";

const listenerMiddleware = createListenerMiddleware();
const combinedUserActions = [
    ...Object.values(currentProgramActions).map(
        (a) => (a as unknown as AsyncThunk<unknown, unknown, object>).fulfilled ?? a
    ),
];

combinedUserActions.forEach((cAction) => {
    listenerMiddleware.startListening({
        actionCreator: cAction,
        effect: (action, listenerApi) => {
            if (cAction.type === action.type) {
                const currentTPAsString = JSON.stringify(
                    (listenerApi.getState() as unknown as RootState).currentTrainingProgram
                );
                window.localStorage.setItem(
                    currentPrincipalTPKey,
                    currentTPAsString
                );
            }
        },
    });
});

export default listenerMiddleware.middleware;
