import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiException, PrincipalResponse } from '../../../types';
import {
  getPrograms,
  getTutorsForProgram,

} from '../../../api/trainingProgram.api';

import { AsyncState, retrieveErrorResponse } from '../../../utils';

// Define the initial state
const initialState: AsyncState<Partial<PrincipalResponse>, ApiException> = {
  loading: true,
  payload: {},
  errors: [],
};

// Async thunks
export const getMyTrainingPrograms = createAsyncThunk(
  'programs/getAllPrograms',
  async (token: string, thunkApi) => {
    try {
      const info = await getPrograms(token);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getMyTutorsForATrainingProgram = createAsyncThunk(
  'programs/getAllTutorsForProgram',
  async (data: {
    tutorId: string;
    role: string;
  }[], thunkApi) => {
    try {
      const info = await getTutorsForProgram(data);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

// Slice
export const trainingProgramDataSlice = createSlice({
  name: 'trainingProgram',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMyTrainingPrograms.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyTrainingPrograms.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = { trainingProgram: action.payload };
      })
      .addCase(getMyTrainingPrograms.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      })

  },
});




// Export actions
export const programActions = {
  getMyTrainingPrograms,
  getMyTutorsForATrainingProgram

};

// Export the reducer function
export default trainingProgramDataSlice.reducer;
