import { createSelector } from '@reduxjs/toolkit';

const selectAuth = (state: { auth: any; }) => state.auth;

export const selectAuthPayload = createSelector(
  [selectAuth],
  (auth) => ({
    payload: auth.payload,
    loading: auth.loading,
  })
);
