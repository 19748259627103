import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useUserAvatar } from "./user-profile.hooks";
import { Link } from "react-router-dom";
import UserImage from "./UserImage";

interface UserAvatarProps {
  navItems: {
    name: string;
    path: string;
  }[];
}

// Component to render the avatar menu
const UserAvatar: React.FC<UserAvatarProps> = ({ navItems }) => {
  const { handleSignout } = useUserAvatar();

  return (
    <Menu>
      <MenuButton as={UserImage}></MenuButton>
      <MenuList>
        {navItems.map((item) => (
          <MenuItem
            key={item.name}
            fontFamily="Montserrat"
            fontSize="sm"
            as={Link}
            to={item.path}
          >
            {item.name}
          </MenuItem>
        ))}
        <MenuItem fontSize="sm" onClick={handleSignout}>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserAvatar;
