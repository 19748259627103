import { mode, whiten, darken } from "@chakra-ui/theme-tools";

export const ButtonStyles = {
  //  style the base or default style
  baseStyle: {
    _disabled: {
      bg: "gray.200",
      color: "gray.600",
      _hover: {
        bg: "gray.200",
      }
    },
    fontSize: "sm",
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    primary: (props: any) => ({
      bg: "primary",
      fontSize: "sm",
      color: "white",
      _hover: {
        bg: mode("secondary", "#0b2050")(props),
        boxShadow: "md",
      },
    }),
    primaryOutline: (props: any) => ({
      bg: "transparent",
      border: "1px solid",
      color: mode("primary", "white")(props),
      fontSize: "sm",
      _hover: {
        transform: "scale(1.02)",
        border: "1px solid",
        boxShadow: "md",
      },
    }),
    secondary: (props: any) => ({
      bg: "secondary",
      fontSize: "sm",
      color: "white",
      _hover: {
        bg: mode("#1944ab", "#0b2050")(props),
        boxShadow: "md",
      },
    }),
    tertiary: () => ({
      bg: "transparent",
      border: "1px solid #FFFFFF",
      color: "primary",
      _hover: {
        transform: "scale(1.02)",
        padding: "3px",
        border: "1px solid",
        boxShadow: "md",
      },
    }),
    special1: (props: any) => ({
      bg: "transparent",
      border: "1px solid gainsboro",
      fontSize: "sm",
      color: mode("black", "white")(props),
      _hover: {
        transform: "scale(1.02)",
        border: "2px solid",
        boxShadow: "md",
      },
    }),
    customOutline: (props: any) => ({
      bg: "transparent",
      border: "1px double #1071E5",
      color: "#1071E5",
      _hover: {
        bg: mode("#1944ab", "#0b2050")(props),
        color: "white",
      },
    }),
    dangerOutline: (props: any) => ({
      bg: "transparent",
      fontSize: "sm",
      border: "1px double #FF6347",
      color: "#FF6347",
      _hover: {
        bg: mode("#FFDCD6 ", "#FFDCD6")(props),
        // color: "#FF6347",
      },
    }),
  },

  //  default values for `size` and `variant`
  defaultProps: {},
};
