import React from 'react';
import { Box, Image, Text, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';

type ImageViewerProps = {
    imageSrc: string;
    title: string;
    subtitle: string;
};

const MotionBox = motion(Box);

const ImageViewer: React.FC<ImageViewerProps> = ({ imageSrc, title, subtitle }) => {
    return (
        <Flex direction="column" alignItems="left" justifyContent="left" p={4} >
            <Box
                bg="#E1E5E7" borderRadius="md"
            >

                <MotionBox
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                    overflow="hidden"
                    _hover={{ cursor: "pointer" }}
                >
                    <Image src={imageSrc} alt={title} />
                </MotionBox>
            </Box>
            <Text fontSize="lg" fontWeight="bold" mt={4}>
                {title}
            </Text>
            <Text fontSize="md" color="gray.500">
                {subtitle}
            </Text>
        </Flex>
    );
};

export default ImageViewer;
