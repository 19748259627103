import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useToast } from "@chakra-ui/react";
import { userActions } from "../../store/features/slices/authSlice";

export const useUserAvatar = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toast = useToast();
  
    const handleSignout = () => {
      dispatch(userActions.signout());
      toast({
        title: "Sign out",
        description: "Signed out successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/");
    };
  
    return { handleSignout };
  };
  
  
 export const useUserProfile = () => {
    const { payload } = useAppSelector((state) => state.auth);
    return payload?.user;
  };