const Greetings = (userName: string) => {
  const currentTime = new Date();
  if (currentTime.getHours() < 12) {
    return `Good Morning, ${userName}`;
  } else if (currentTime.getHours() < 18) {
    return `Good Afternoon, ${userName}`;
  } else {
    return `Good Evening, ${userName}`;
  }
};

export default Greetings;
