/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import {
  Box,
  Flex,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  Button,
  HStack,
  FlexProps,
} from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import UserAvatar from "../UserProfile/UserAvatar";
import { FaBars, FaBell } from "react-icons/fa";
import Greetings from "../../utils/Greetings";

interface Props extends FlexProps {
  handlerSidebar: () => void;
}

export const navItems = [
  {
    name: "Account",
    path: "/dashboard/settings/account",
  },

  {
    name: "My Programs",
    path: "/dashboard/programs",
  },
  {
    name: "Settings",
    path: "/dashboard/settings",
  },
];
const Nav = ({ handlerSidebar }: Props) => {
  const { isOpen, onToggle } = useDisclosure();
  const location = useLocation();
  const { payload } = useAppSelector((state) => state.auth);
  // console.log(user)
  // const { firstName, lastName } = splitName(user.firstName);
  const navigate = useNavigate();
  return (
    <Flex
      fontFamily="Montserrat"
      bg={useColorModeValue("white", "gray.800")}
      color={useColorModeValue("gray.600", "white")}
      minH={"60px"}
      py={{ base: 2 }}
      px={{ base: 4 }}
      zIndex={10}
      // pl="20%"
      // pr="20%"
      boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;"
      align={"center"}
      pos={"sticky"}
      top={0}
      justify={"space-between"}
      w={"full"}
    >
      <Box>
        <Box
          display={{
            base: "inline",
            lg: "none",
          }}
          onClick={() => handlerSidebar()}
          as={FaBars}
        ></Box>
        <Text
          display={{
            base: "none",
            lg: "inline",
          }}
        >
          {payload?.user && (
            <Text as="span" color={useColorModeValue("primary", "blue.300")}>
              {Greetings(`${payload?.user?.firstName}`)}
            </Text>
          )}
          {/* {user && { Greetings(lastName).split(",")[0] },
            <Text as="span" color={"primary"} fontWeight={"bold"}>
              {Greetings(user.firstName).split(",")[1]}
            </Text>
          } */}
        </Text>
      </Box>
      <HStack gap="1em">
        <SettingsIcon
          onClick={() => navigate("/dashboard/settings")}
          cursor={"pointer"}
        />
        <FaBell
          onClick={() => navigate("/dashboard/communication")}
          cursor={"pointer"}
        />
        {/* <ColorModeSwitcher /> */}
        {!payload?.user ? (
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >
            <Button
              as={Link}
              fontSize={"sm"}
              fontWeight={400}
              variant={"link"}
              to={"/signin"}
            >
              Sign in
            </Button>
            <Button
              as={Link}
              variant="primary"
              display={{ base: "none", md: "inline-flex" }}
              to={"/signup"}
            >
              Start For Free
            </Button>
            {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
          </Stack>
        ) : (
          <UserAvatar navItems={navItems} />
        )}{" "}
      </HStack>
    </Flex>
  );
};

export default Nav;
