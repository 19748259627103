export const PATH_NAMES = {
    ROOT: '/',
    ABOUT: '/about',
    DATA_HANDLING: '/data-handling',
    FAQ: '/faq',
    INSTITUTIONS: '/institutions',
    CONTACT_US: '/contact-us',
    SIGN_UP: '/signup',
    SIGN_IN: '/signin',
    FORGOT_PASSWORD: '/forgot-password',
    VERIFY_OTP: '/auth/verify-otp',
    ONBOARDING: '/onboarding',
    DASHBOARD: '/dashboard',
    COMMUNICATION: '/dashboard/communication',
    PROGRAMS: '/dashboard/programs',
    CREATE_PROGRAM: '/dashboard/programs/create-program',
    PERFORMANCE: '/dashboard/performance',
    SETTINGS: '/dashboard/settings',
    ACCOUNT: '/dashboard/settings/account',
    PROFILE: '/dashboard/settings/profile',
    SUPPORT: '/dashboard/support',
    NOT_FOUND: '*',
  };
  