import { useEffect, useState } from 'react';
import liahstudy01 from '../../assets/images/liahstudy_first.png'
import liahstudy02 from '../../assets/images/liahstudy_second.png'

const useScrollPercent = () => {
    const [scrollPercent, setScrollPercent] = useState(0);

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolled = (scrollTop / docHeight) * 100;
        setScrollPercent(scrolled);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return scrollPercent;
};

export const useImageGrid = () => {
    const images = [
       
        liahstudy01,
        liahstudy02,
        liahstudy01,
        liahstudy02,
        liahstudy01,
        liahstudy02,
        liahstudy01,
        liahstudy02,
        liahstudy01,
        liahstudy02,
        liahstudy01,
        liahstudy02,
        liahstudy01,
        liahstudy02,
        liahstudy01,
        liahstudy02,
        liahstudy01,
        liahstudy02,
        liahstudy01,
        liahstudy02,
        liahstudy01,
        liahstudy02,
        liahstudy01,
        liahstudy02,
        liahstudy01,
        liahstudy02,
        liahstudy01,
        liahstudy02,
        liahstudy01,
        
    ];
    return { images, useScrollPercent }
}
