import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { CustomizedModal } from "../components/Modal/Modal";
import { useAppSelector } from "../app/hooks";
import { routes } from "./routes";

import Notfound from "../Pages/NotFound/Notfound";
import {useCallback, useEffect, useState} from "react";
import eventBus from "../utils/eventbus";
import ErrorModal from "../components/common/ErrorModal";
import {ErrorType} from "../../types";


const AppRouter = () => {
    const isModal = useAppSelector((state) => state.utility.isModalOpen);
    const [isOpen, setIsOpen] = useState(false);

    const [error, setError] = useState<ErrorType | null>(null);


    useEffect(() => {
        eventBus.on('showErrorModal', (errorData: ErrorType) => {
            setError(errorData);
            setIsOpen(true);
        });

        return () => {
            eventBus.off('showErrorModal');
        };
    }, []);

    const handleClose = useCallback(() => {
        setIsOpen(false);
        setError(null);
    }, []);
    useEffect(() => {
        // Close the modal if navigating to the /signin route
        if (window.location.pathname === '/signin') {
            handleClose();
        }
    }, [handleClose]);
    return (
        <HelmetProvider>
            <Router>
                {isModal && <CustomizedModal />}
                {error && (
                    <ErrorModal isOpen={isOpen} onClose={handleClose} error={error} />
                )}
                <Routes>
                    {routes.map((route) => (
                        <Route key={route.path} path={route.path} element={route.element}>
                            {route.children?.map((child) => (
                                <Route
                                    key={child.path}
                                    path={child.path}
                                    element={child.element}
                                >
                                    {child.children?.map((grandchild) => (
                                        <Route
                                            key={grandchild.path}
                                            path={grandchild.path}
                                            element={grandchild.element}
                                        >
                                            {grandchild.children?.map((greatGrandchild) => (
                                                <Route
                                                    key={greatGrandchild.path}
                                                    path={greatGrandchild.path}
                                                    element={greatGrandchild.element}
                                                />
                                            ))}
                                        </Route>
                                    ))}
                                </Route>
                            ))}
                        </Route>
                    ))}
                    {/* Our 404 page route */}
                    <Route path="*" element={<Notfound />} />
                </Routes>
            </Router>
        </HelmetProvider>
    );
};

export default AppRouter;
