import {
  Flex,
  Box,
  FormControl,
  Button as ChakraBtn,
  Text,
  VStack,
  useColorModeValue,
  InputRightElement,
  InputGroup,
  IconButton,
} from "@chakra-ui/react";
import "./Login/loginStyles.css";
import 'primereact/resources/primereact.min.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primeicons/primeicons.css';
import { Link } from "react-router-dom";
import { Logo } from "../../components/common/Logo";
import { Helmet } from "react-helmet-async";
import { useSignupLogin } from "../../hooks/auth.hooks";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useEffect, useRef } from "react";
import { Button } from 'primereact/button';
import { Toast, ToastMessage } from 'primereact/toast';


export default function SignIn() {
  // const toast = useRef<Toast>(null);
  const { msg, onInputChange, isLoading, inputValues, onSubmit, isFormComplete, togglePasswordVisibility, showPassword } =
    useSignupLogin("signin");
  // useEffect(() => {
  //   if (msg) {
  //     toast.current?.show(msg);
  //   }
  // }, [msg]);


  return (
    <VStack w={{base: "100%", md: "50%"}} alignItems="flex-start" justifyContent="space-between" h="full" overflowY="hidden" p={{ base: 0, sm: 1, md: 4, lg: 4, xl: 5 }}>
      <Helmet>
        <title>Sign In | Liahtutor</title>
        <meta
          name="description"
          content="Tutor platform for LiahStudy Enhance e-learning"
        />
      </Helmet>
      {/* <Toast ref={toast} className="p-button-warning" position="bottom-right" /> */}

      <VStack
        bg={useColorModeValue("white", "black")}
        px="10px"
        rounded={"md"}
        py={10}
        alignItems="flex-start"
        // boxShadow="md"
        w="100%"
        // w={{ base: "100%", sm: "100%", md: "70%", lg: "80%", xl: "70%" }}
        pt={8}
      >
        {/* <Box pos="absolute" top="3rem" left="2rem"> */}
        <Logo />
        {/* </Box> */}
        <Text as="h3" textAlign={"center"} fontWeight={600}
          mb=".5rem"
          fontSize={{ sm: "20px", md: "35px" }}
        >
          Sign in
        </Text>
        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "6px",
          }}
          onSubmit={onSubmit}
        >
          <FormControl>
            <Box
              as="input"
              required
              rounded={"md"}
              placeholder={"Enter your email"}
              _placeholder={{ color: "gray.500", fontSize: "12" }}
              type="email"
              name="email"
              value={inputValues.email}
              _focus={{
                outline: "none",
              }}
              fontSize={{
                base: "xs",
                md: "sm",
              }}
              w="full"
              onChange={onInputChange}
              border={" 1px solid gainsboro"}
              bg={useColorModeValue("white", "black")}
              p={"0.5em"}
              borderWidth={"2px"}
            />
          </FormControl>
          <FormControl>
            <InputGroup>
              <Box
                as="input"
                required
                rounded={'5px'}
                placeholder={'Enter your password'}
                _placeholder={{ color: 'gray.500', fontSize: '12' }}
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={inputValues.password}
                _focus={{
                  outline: 'none',
                }}
                fontSize={{
                  base: 'xs',
                  md: 'sm',
                }}
                w="full"
                border={'1px solid gainsboro'}
                bg={useColorModeValue('white', 'black')}
                p={'0.5em'}
                onChange={onInputChange}
                borderWidth={'2px'}
              />
              <InputRightElement width="3rem">
                <IconButton
                  h="1.5rem"
                  variant="unstyled"
                  size="sm"
                  onClick={togglePasswordVisibility}
                  icon={showPassword ? <ViewOffIcon /> : <ViewIcon />} aria-label={"Toggle Password visibility"} />
              </InputRightElement>
            </InputGroup>
          </FormControl>

          {/* <Text fontSize={12}>
            By signing up, I accept the Liah Technologies{" "}
            <Text as="span" color={useColorModeValue("primary", "blue.300")} cursor={"pointer"}>
              Terms of Service
            </Text>{" "}
            and acknowledge the{" "}
            <Text as="span" color={useColorModeValue("primary", "blue.300")} cursor={"pointer"}>
              Privacy Policy
            </Text>
            .
          </Text> */}
          <ChakraBtn as={Button}
            type="submit"
            variant={"primary"}
            isDisabled={!isFormComplete()}
            w="100%"
            isLoading={isLoading}
            loadingText="Submitting"
          >
            Sign in
          </ChakraBtn>
          <Flex justifyContent={"space-between"} fontSize={12}>
            <Text
              as={Link}
              to="/forgot-password"
              color={useColorModeValue("primary", "blue.300")}
              _hover={{
                textDecoration: "underline",
              }}
              cursor={"pointer"}
            >
              Forgot your password?
            </Text>
            <Text
              as={Link}
              to="/signup"
              color={useColorModeValue("primary", "blue.300")}
              cursor={"pointer"}
              _hover={{
                textDecoration: "underline",
              }}
            >
              Don't have an account?
            </Text>
          </Flex>
        </form>
      </VStack>
      {/* <Box fontSize="xs">
        <Text>
          Don't have an account? {" "}
          <Text
            as={Link}
            to="/signup"
            color={useColorModeValue("primary", "blue.300")}
            cursor={"pointer"}
            _hover={{
              textDecoration: "underline",
            }}
          >
            Create one here
          </Text>
        </Text>
      </Box> */}
      {/* <Box bg="rgba(255, 255, 255, 0.2)" p=".1rem" rounded="lg" boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)" backdropFilter="blur(5px)" border="1px solid rgba(255, 255, 255, 0.3)">
        <Logo />
      </Box> */}
    </VStack>
  );
}
