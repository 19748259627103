import React from 'react';
import { Box, Grid, useColorModeValue } from '@chakra-ui/react';
import { useImageGrid } from './ImageGrid.hooks';
import { ImageWithFallback } from './ImageWithFallback';



const ImageGrid: React.FC = () => {
    const { useScrollPercent, images } = useImageGrid()
    const scrollPercent = useScrollPercent();
    const rowLength = 4; // Number of images per row

    return (
        <Box zIndex={-1}
            maxW="100%"
            overflow="hidden"
            bg={useColorModeValue("#dce3ee", "black")} p={5} pos="fixed" top="0" width="100%" h="100vh">
            <Grid transform="rotate(-3.5deg)" templateColumns={{
                base: 'repeat(2, 1fr)', // 2 columns on mobile
                sm: 'repeat(3, 1fr)', // 3 columns on small screens
                md: `repeat(${rowLength}, 1fr)` // 4 columns on medium screens and above
            }}
                gap={{
                    base: 'clamp(10px, 5vw, 20px)', // Smaller gap on mobile
                    md: 'clamp(14px, 3.0967741935vw, 48px)' // Larger gap on larger screens
                }}>
                {images.map((src, index) => {
                    const rowIndex = Math.floor(index / rowLength);
                    const direction = rowIndex % 2 === 0 ? 1 : -1; // Alternate direction for each row
                    const style = {
                        transform: `translate(${(1 - scrollPercent / 100) * 90 * direction}px, 0px)`,
                        // boxShadow: '-6px 6px 24px #00000040',
                    };
                    return (
                        <ImageWithFallback
                            key={index}
                            src={src}
                            alt={`image-${index}`}
                            style={style}
                            width={{
                                base: "45vw", // Smaller width on mobile
                                md: "30vw", // Larger width on medium screens and above
                            }}
                            height="100%"
                        />
                    );
                })}
            </Grid>
        </Box>
    );
};


export default ImageGrid;
