//@ts-nocheck
import {
    Box,
    Card,
    Flex,
    Heading,
    HStack,
    Icon,
    Image,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState, } from "react";
import {
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
} from "@chakra-ui/react";
import { Link, Outlet } from "react-router-dom";
import { useCreateTPLayout, useToggleSideBar } from "../components/CreateTrainingProg/createTp.hooks";
import {
    FiCheckCircle,
    FiUser,
    FiFileText,
    FiSettings,
    FiPlayCircle,
    FiFilePlus,
    FiSave,
    FiCamera
} from "react-icons/fi";
import { FaAcquisitionsIncorporated } from "react-icons/fa"; // Example icons
import ConfirmationModal from "../components/common/ConfirmationModal";
import ExpandIcon from "../components/common/icons/expand.svg"
import CollapseIcon from "../components/common/icons/collapse.svg"

type Props = {};


const CreateProgramSidebar = ({ steps, currentIndex, completedSteps, isCollapsed, setIsCollapsed }) => {
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const textColorMode = useColorModeValue("gray.600", "white");

    useEffect(() => {
        const handleResize = () => setDeviceWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Define a list of icons and colors for each step
    const stepIcons = [FaAcquisitionsIncorporated, FiFileText, FiSettings, FiFilePlus, FiPlayCircle, FiSave];
    const stepColors = ["primary", "secondary", "orange.300", "purple.300", "teal.300", "red.300"];

    return (
        <Stepper
            index={currentIndex}
            orientation={deviceWidth < 786 ? "horizontal" : "vertical"}
            height={deviceWidth < 786 ? "50px" : "400px"}
            gap="0"
            pos={{ base: "absolute", md: "initial" }}
            zIndex={24}
            top="3.5em"
            bg={{ base: "white", md: "initial" }}
            left={0}
            px={{ base: "1em", md: "initial" }}
            w={{ base: "100vw", md: isCollapsed ? "50px" : "100%" }} // Adjust width when collapsed
            overflowX="auto"
        >
            {steps.map((step, index) => (
                <Step key={index}
                    transition="all 0.5s ease-in-out"
                >
                    <StepIndicator
                        as={completedSteps[index] ? Link : "div"}
                        to={completedSteps[index] ? step.path : undefined}
                        cursor={completedSteps[index] ? "pointer" : "not-allowed"}
                        onClick={(e) => {
                            if (!completedSteps[index]) e.preventDefault();
                        }}
                        bg={completedSteps[index] ? "gray.300" : stepColors[index]}
                        color="white"
                        borderRadius="full"
                        p={2}
                    >
                        <StepStatus
                            style={{ backgroundColor: "red" }}
                            complete={<FiCheckCircle boxSize={4} />}
                            incomplete={<Icon as={stepIcons[index]} boxSize={4} color="white" />}
                            active={<Icon as={FiPlayCircle} boxSize={5} color="white" />}
                        />
                    </StepIndicator>
                    {!isCollapsed && ( // Only show when not collapsed
                        <Box
                            flexShrink="0"
                            color={textColorMode}
                            display={{ base: "none", md: "block" }}
                            ml={2}
                        >
                            <StepTitle
                                as={completedSteps[index] ? Link : "span"}
                                to={completedSteps[index] ? step.path : undefined}
                                cursor={completedSteps[index] ? "pointer" : "not-allowed"}
                                fontWeight={currentIndex === index ? "bold" : "normal"}
                                fontSize="lg"
                            >
                                {step.title}
                            </StepTitle>
                            <StepDescription>
                                <Box maxW="20vw" color={currentIndex === index ? stepColors[index] : "gray.500"}>
                                    {step.description}
                                </Box>
                            </StepDescription>
                        </Box>
                    )}
                    {index < steps.length - 1 && (
                        <Box
                            w={{ base: "3", md: deviceWidth < 786 ? "100%" : "50%" }}>
                            <StepSeparator
                                borderColor={completedSteps[index + 1] ? "gray.300" : stepColors[index + 1]}
                                borderWidth="2px"
                            />
                        </Box>
                    )}
                </Step>
            ))}
            <HStack
                title="toggle steps menu"
                mt="2rem"
                mb=".5rem"
                justifyContent="center"
                alignItems="center"
                w="2rem"
                display={{ base: "none", md: "block" }}

                p=".2em"
                _hover={{ backgroundColor: "#F7F8F9", cursor: "pointer" }}
                rounded="lg"
                onClick={() => setIsCollapsed(!isCollapsed)} // Toggle state
            >

                <svg
                    data-icon-name="collapseIcon"
                    viewBox="0 0 18 18"
                    width="18"
                    height="18"
                    aria-hidden="true"
                    style={{
                        transform: isCollapsed ? "rotate(180deg)" : "rotate(0deg)", // Rotate icon when collapsed
                        transition: "transform 0.3s ease-in-out"
                    }}
                >
                    <path
                        fillRule="evenodd"
                        d="M5.46 8.846l3.444-3.442-1.058-1.058-4.5 4.5 4.5 4.5 1.058-1.057L5.46 8.84zm7.194 4.5v-9h-1.5v9h1.5z"
                    ></path>
                </svg>
            </HStack>

        </Stepper>
    );
};


const CreateTrainingProgramLayout = (props: Props) => {
    const { completedSteps, currentIndex, steps, isOpen, onClose, handleConfirmLeave } = useCreateTPLayout()
    const { dashboardSidebarOpen } = useToggleSideBar()
    const [isStepsCollapsed, setIsStepsCollapsed] = useState(false);
    return (
        <Box m="0 auto">
            <ConfirmationModal
                isOpen={isOpen}
                onClose={onClose}
                onConfirm={handleConfirmLeave}
                title="Unsaved Changes"
                description="You have unsaved changes. If you leave this page, your changes will be lost. Are you sure you want to leave?"
                confirmButtonText="Leave"
                cancelButtonText="Stay"
            />
            <Box
                display={{
                    base: "block",
                    md: "none",
                }}
                px={{
                    base: "4",
                    md: "3",
                }}
                // bgColor={"white"}
                h={"fit-content"}
            >
                <CreateProgramSidebar
                    steps={steps}
                    currentIndex={currentIndex}
                    completedSteps={completedSteps}
                />
            </Box>
            <Flex
                w="100%"
                // pt={8}
                px={{
                    base: "4",
                    md: "unset",
                }}
            >
                <Flex
                    display={{
                        base: "none",
                        md: "flex",
                    }}
                    flexDir="column"
                    w={"fit-content"}
                    h={"fit-content"}
                    justifyContent={"center"}
                    bgColor={"white"}
                    pos="fixed"
                    top="5.8rem"
                    left={`${dashboardSidebarOpen ? '14rem' : '6rem'}`}
                    transition="all 0.5s ease-in-out"
                    bottom={0}
                    pt="2rem"
                    rounded={"md"}
                    boxShadow={"sm"}
                    pl="1rem"
                >
                    <CreateProgramSidebar
                        steps={steps}
                        currentIndex={currentIndex}
                        completedSteps={completedSteps}
                        isCollapsed={isStepsCollapsed}
                        setIsCollapsed={setIsStepsCollapsed}
                    />



                </Flex>

                <Card bgColor={{ base: "transparent", md: "white" }} boxShadow={{ base: "none", md: "initial" }} ml={{
                    base: "0",
                    md: `${isStepsCollapsed ? '10%' : '31%'}`
                }} mt={{ base: "3.5em", md: "2em" }} mb={{ base: "initial", md: "2em" }} p={{ base: 0, md: "8" }}
                    transition="all 0.25s ease-in-out"
                    w={{ base: "100vw", md: `${dashboardSidebarOpen ? `${isStepsCollapsed ? '89%' : '65%'}` : `${isStepsCollapsed ? '89%' : '67.5%'}`}` }}>
                    <Box mb={"1em"}>
                        <Heading
                            fontFamily="montserrat"
                            mb={{
                                mb: 8,
                            }}
                            fontSize={{
                                base: "xl",
                                md: "2xl",
                                lg: "2xl",
                            }}
                            color={useColorModeValue("gray.600", "white")}
                        >
                            Create {steps[currentIndex].title}
                        </Heading>
                        <Text fontSize={"sm"}>
                            {steps[currentIndex].description}
                        </Text>
                    </Box>
                    <Outlet />
                </Card>
            </Flex>
        </Box>
    );
};

export default CreateTrainingProgramLayout;
