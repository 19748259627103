import { principalTokenKey } from "../constants";
import { user } from "../Pages/interfaces/user";
import { PrincipalResponse, SigninResponse } from "../types";
import { backend, endpoints } from "./config.api";

export const getMe = () => backend.get<PrincipalResponse>(endpoints.tutors.me);

export const getTutorByEmail = (email: string) =>
  backend.post<PrincipalResponse>(endpoints.tutors.me, {
    email
  })
export const recoverTutorPassword = (email: string) =>
  backend.post<SigninResponse>(endpoints.auth.recoverPassword, {
    email,
  });
export const signin = (email: string, password: string) =>
  backend.post<SigninResponse>(endpoints.auth.signin, {
    email,
    password,
  });

export type TSignUpUser = Record<'email' | 'password', string> & {
  email: string;
  password: string;
  passwordConfirm: string;
  tel: string;
  firstName: string;
  lastName: string;
};
export const signup = (data: TSignUpUser) =>
  backend.post<PrincipalResponse>(endpoints.auth.signup, {
    ...data,
  });

export const verifyOtp = (otp: string, email: string) =>
  backend.post<{}>(endpoints.auth.verifyOtp, {
    otp,
    email,
  });
export const forgetPassword = (email: string) =>
  backend.post<PrincipalResponse>(endpoints.auth.forgetPassword, {
    email,
  });
export const resetPassword = (
  email: string,
  password: string,
  confirmPassword: string
) =>
  backend.post<PrincipalResponse>(endpoints.auth.resetPassword, {
    email,
    password,
    confirmPassword,
  });

export const uploadPhoto = (data: { email: string, file: File }) => {
  const token = localStorage.getItem(principalTokenKey) || ''
  const formData = new FormData();
  formData.append("email", data.email);
  formData.append("file", data.file);
  return backend.post<PrincipalResponse>(endpoints.auth.uploadPhoto, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${token}`
    },
  });

};
export const updateUserInformation = (data: Partial<user>) => {
  const token = localStorage.getItem(principalTokenKey) || '';

  return backend.patch<PrincipalResponse>(endpoints.auth.updateUserInfo, data, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
  });
};



export const addBio = (data: { email: string, text: string }) => {
  const token = localStorage.getItem(principalTokenKey) || '';

  return backend.post<PrincipalResponse>(endpoints.auth.uploadBio, data, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
  });
}

export type TSocial = {
  facebook?: string;
  x?: string;
  linkedin?: string;
  web?: string;
  youtube?: string;
};
// export const uploadSocial = (social: TSocial) =>
//   backend.post<PrincipalResponse>(endpoints.auth.uploadSocial);

export const uploadSocial = (data: { social: TSocial, email: string }) => {
  const token = localStorage.getItem(principalTokenKey) || '';

  return backend.post<PrincipalResponse>(endpoints.auth.uploadSocial, data, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
  });
};