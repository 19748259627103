import {
    Box,
    FormControl,
    FormErrorMessage,
    Input,
    VStack,
    Button as ChakraBtn,
    Text,
    useColorModeValue,
    Link as ChakraLink,
    HStack,
    PinInput,
    PinInputField,
    InputGroup,
    InputRightElement,
    IconButton,
    Progress,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Logo } from "../../components/common/Logo";
import { useForgotPassword } from "../../hooks/auth.hooks";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

export default function ForgotPassword() {
    const {
        formik,
        isLoading,
        serverError,
        isSubmitted,
        step,           // Current step in the flow
        handleOTPSubmit, // Handles OTP verification
        showPassword,
        showPasswordConfirm,
        togglePasswordVisibility,
        togglePasswordConfirmVisibility,
        passwordStrength,
        passwordStrengthMessages,
        passwordStrengthMessagesFixed,
        checkPasswordStrength,
        passwordFormik,  // Formik instance for password form
        value1, value2, value3, value4, // Refs for OTP fields
    } = useForgotPassword();

    const backgroundColor = useColorModeValue("white", "gray.900");
    const inputBg = useColorModeValue("gray.50", "gray.800");
    // const borderColor = useColorModeValue("gray.300", "gray.600");
    const textColor = useColorModeValue("gray.800", "gray.200");

    return (
        <VStack
            alignItems="flex-start"
            justify="flex-start"
            w={{ base: "100%", md: "50%" }}
            justifyContent="space-between"
            h="full"
            overflowY="hidden"
            p={{ base: 0, sm: 1, md: 4, lg: 4, xl: 5 }}
            spacing={6}
            bg={backgroundColor}
        >
            <Helmet>
                <title>Forgot Password | Liahtutor</title>
                <meta name="description" content="Reset your password for the LiahTutor platform" />
            </Helmet>

            <VStack
                px="10px"
                rounded={"md"}
                py={10}
                alignItems="flex-start"
                justify="flex-start"
                w="100%"
                pt={8}
            >
                <Logo />

                <Box w="100%">
                    <VStack alignItems="left" spacing={4}>
                        {step === "email" && (
                            <>
                                <Text fontSize={{ base: "lg", md: "2xl" }} fontWeight="bold" textAlign="left" color={textColor}>
                                    Forgot Your Password?
                                </Text>
                                <Text textAlign="left" color="gray.500">
                                    Enter your email address, and we’ll send you an OTP to reset your password.
                                </Text>
                                <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                                    <FormControl isInvalid={!!formik.errors.email && formik.touched.email}>
                                        <Box
                                            as="input"
                                            required
                                            rounded={"md"}
                                            placeholder={"Enter your email"}
                                            _placeholder={{ color: "gray.500", fontSize: "12" }}
                                            type="email"
                                            id="email"
                                            {...formik.getFieldProps("email")}
                                            w="full"
                                            bg={inputBg}
                                            border={"1px solid gainsboro"}
                                            p={"0.5em"}
                                            borderWidth={"2px"}
                                            _focus={{ outline: "none", borderColor: "blue.500" }}
                                        />
                                        <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                                    </FormControl>

                                    {serverError && (
                                        <Text color="red.500" mt={2} textAlign="center">
                                            {serverError}
                                        </Text>
                                    )}

                                    <ChakraBtn
                                        mt={6}
                                        colorScheme="blue"
                                        isLoading={isLoading}
                                        // isDisabled={
                                        //     // !passwordFormik.isValid ||
                                        //     !passwordFormik.
                                        // }
                                        type="submit"
                                        variant="primary"
                                        w="100%"
                                        loadingText="Sending..."
                                    >
                                        Send OTP
                                    </ChakraBtn>
                                </form>
                            </>
                        )}

                        {step === "otp" && (
                            <>
                                <Text fontSize={{ base: "lg", md: "2xl" }} fontWeight="bold" textAlign="left" color={textColor}>
                                    Enter OTP
                                </Text>
                                <Text textAlign="left" color="gray.500">
                                    We've sent you an OTP to your email. Please enter it below.
                                </Text>

                                <HStack color={"primary"}>
                                    <PinInput type="alphanumeric">
                                        <PinInputField w={"4em"} h={"6em"} ref={value1} />
                                        <PinInputField w={"4em"} h={"6em"} ref={value2} />
                                        <PinInputField w={"4em"} h={"6em"} ref={value3} />
                                        <PinInputField w={"4em"} h={"6em"} ref={value4} />
                                    </PinInput>
                                </HStack>

                                {serverError && (
                                    <Text color="red.500" mt={2} textAlign="center">
                                        {serverError}
                                    </Text>
                                )}

                                <ChakraBtn
                                    mt={6}
                                    colorScheme="blue"
                                    isLoading={isLoading}
                                    // isDisabled={
                                    //     value1.current?.value !== ""
                                    //     // ||
                                    //     // !value2 || !value3 || !value4 
                                    //     // passwordFormik.isSubmitting
                                    // }
                                    onClick={handleOTPSubmit}
                                    variant="primary"
                                    w="100%"
                                    loadingText="Verifying..."
                                >
                                    Verify OTP
                                </ChakraBtn>
                            </>
                        )}

                        {step === "password" && (
                            <>
                                <Text fontSize={{ base: "lg", md: "2xl" }} fontWeight="bold" textAlign="left" color={textColor}>
                                    Reset Password
                                </Text>
                                <Text textAlign="left" color="gray.500">
                                    Enter a new password for your account.
                                </Text>

                                <form onSubmit={passwordFormik.handleSubmit} style={{ width: "100%" }}>
                                    {/* Password Input */}
                                    <FormControl
                                        isInvalid={!!passwordFormik.errors.password && passwordFormik.touched.password}
                                    >
                                        <InputGroup>
                                            <Input
                                                required
                                                rounded={'5px'}
                                                placeholder={'Enter your password'}
                                                _placeholder={{ color: 'gray.500', fontSize: '12' }}
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                value={passwordFormik.values.password}
                                                _focus={{ outline: 'none' }}
                                                fontSize={{ base: 'xs', md: 'sm' }}
                                                w="full"
                                                border={'1px solid gainsboro'}
                                                bg={inputBg}
                                                p={'0.5em'}
                                                onChange={(e) => {
                                                    passwordFormik.handleChange(e);
                                                    checkPasswordStrength(e.target.value); // Check password strength as the user types
                                                }}
                                                onBlur={passwordFormik.handleBlur}
                                                borderWidth={'2px'}
                                            />
                                            <InputRightElement width="3rem">
                                                <IconButton
                                                    h="1.5rem"
                                                    variant="unstyled"
                                                    size="sm"
                                                    onClick={togglePasswordVisibility}
                                                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                                    aria-label={"Toggle Password visibility"}
                                                />
                                            </InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage fontSize="xs">{passwordFormik.errors.password}</FormErrorMessage>
                                    </FormControl>

                                    {/* Password Strength Indicator */}
                                    {passwordStrength > 0 && (
                                        <>
                                            <Progress
                                                value={passwordStrength * 25}
                                                size="xs"
                                                colorScheme={
                                                    passwordStrength < 2 ? 'red' :
                                                        passwordStrength < 3 ? 'yellow' :
                                                            'green'
                                                }
                                                mt="1"
                                            />
                                            <Text fontSize="xs" color={passwordStrength < 3 ? 'red.500' : 'green.500'}>
                                                Password strength: {passwordStrengthMessagesFixed[passwordStrength]}
                                            </Text>
                                            {passwordStrength < 3 && (
                                                <Text fontSize="xs" color="red.500" mt={1}>
                                                    {passwordStrengthMessages.join(" ")}
                                                </Text>
                                            )}
                                        </>
                                    )}

                                    {/* Confirm Password Input */}
                                    <FormControl
                                        mt={4}
                                        isInvalid={!!passwordFormik.errors.confirmPassword && passwordFormik.touched.confirmPassword}
                                    >
                                        <InputGroup>
                                            <Box
                                                as="input"
                                                required
                                                rounded={'5px'}
                                                placeholder={'Confirm your password'}
                                                _placeholder={{ color: 'gray.500', fontSize: '12' }}
                                                type={showPasswordConfirm ? 'text' : 'password'}
                                                name="confirmPassword"
                                                value={passwordFormik.values.confirmPassword}
                                                _focus={{ outline: 'none' }}
                                                fontSize={{ base: 'xs', md: 'sm' }}
                                                w="full"
                                                border={'1px solid gainsboro'}
                                                bg={inputBg}
                                                p={'0.5em'}
                                                onChange={passwordFormik.handleChange}
                                                onBlur={passwordFormik.handleBlur}
                                                borderWidth={'2px'}
                                            />
                                            <InputRightElement width="3rem">
                                                <IconButton
                                                    h="1.5rem"
                                                    variant="unstyled"
                                                    size="sm"
                                                    onClick={togglePasswordConfirmVisibility}
                                                    icon={showPasswordConfirm ? <ViewOffIcon /> : <ViewIcon />}
                                                    aria-label={"Toggle Confirm Password visibility"}
                                                />
                                            </InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage fontSize="xs">{passwordFormik.errors.confirmPassword}</FormErrorMessage>
                                    </FormControl>

                                    <ChakraBtn
                                        mt={6}
                                        isDisabled={
                                            !passwordFormik.isValid ||
                                            !passwordFormik.dirty ||
                                            passwordFormik.isSubmitting
                                        }
                                        colorScheme="blue"
                                        isLoading={passwordFormik.isSubmitting}
                                        type="submit"
                                        variant="primary"
                                        w="100%"
                                        loadingText="Resetting..."
                                    >
                                        Reset Password
                                    </ChakraBtn>

                                </form>
                            </>
                        )}

                        <ChakraLink as={Link} to="/signin" w="fit-content" color="blue.500" fontWeight="medium" _hover={{ textDecoration: "underline" }} mt={4}>
                            Go back to Sign In
                        </ChakraLink>
                    </VStack>
                </Box>

                <Text fontSize="sm" color="gray.500" textAlign="center">
                    By using our service, you agree to our{" "}
                    <ChakraLink href="/terms" color="blue.500" _hover={{ textDecoration: "underline" }}>
                        Terms of Service
                    </ChakraLink>{" "}
                    and{" "}
                    <ChakraLink href="/privacy" color="blue.500" _hover={{ textDecoration: "underline" }}>
                        Privacy Policy
                    </ChakraLink>.
                </Text>
            </VStack>
        </VStack>
    );
}
