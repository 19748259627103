import React from "react";
import { Box, Heading, Image, Text } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Ensure this CSS is imported

import explorPic from "../../assets/images/african-american-man-office.jpg";
import bench from "../../assets/images/bench.jpg";
import remoteWork from "../../assets/images/remote-job-technology-people-concept-young-african-business-woman-with-laptop-computer-papers-working-home-office-covid19-health-crisis-min.jpg";

const CarouselComponent: React.FC = () => {
  const slides = [
    {
      image: bench,
      heading: "Create an account",
      text: "Register your account if you want to create a Training Program.",
    },
    {
      image: explorPic,
      heading: "Craft the Training Program",
      text: "Once you are authenticated, begin creating the program and share knowledge.",
    },
    {
      image: remoteWork,
      heading: "Share Your Expertise",
      text: "Share your knowledge with the world through well-crafted training programs.",
    },
  ];

  return (
    <Box position="relative" h="100vh">
      <Box
        as={Carousel}
        autoPlay
        infiniteLoop
        showThumbs={false}
        showStatus={false}
        interval={5000}
        h="100vh"
        w="100%"
        pos="relative"
      >
        {slides.map((slide, index) => (
          <Box key={index} position="relative" h="100vh">
            <Image src={slide.image} alt={`Slide ${index + 1}`} objectFit="cover" h="100%" w="100%" />
            <Box
              position="absolute"
              bottom="43%"
              left="20%"
              textAlign="left"
              transform="translateX(-15%)"
              p={4}
              borderRadius="2xl"
              zIndex="2"

            >
              <Heading
                bg="rgba(167, 168, 219, 0.1)"
                borderRadius="xs"
                backdropFilter="blur(5px)" as="h3" color="blackAlpha.700" fontSize="57px">
                {slide.heading}
              </Heading>
              <Text
                borderRadius="xs"
                bg="rgba(167, 168, 219, 0.1)"
                backdropFilter="blur(5px)" color="white" fontSize="lg">
                {slide.text}
              </Text>
            </Box>
          </Box>
        ))}
      </Box>

      {/* Overlay Box */}
      {/* <Box
        position="absolute"
        top={0}
        left={0}
        h="100vh"
        w="100%"
        bg="rgba(167, 168, 219, 0.1)"
        backdropFilter="blur(1px)"
        zIndex="1"
      /> */}
    </Box>
  );
};

export default CarouselComponent;
