import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

type HeroGradientProps = BoxProps & {
    children: React.ReactNode;
};

function HeroGradient({ children, ...rest }: HeroGradientProps) {
    const bg = `linear-gradient(135deg, 
        rgba(203, 89, 247, 1) 0%, 
        rgba(130, 89, 247, 1) 20%,
        rgba(80, 119, 255, 1) 40%, 
        rgba(150, 190, 255, 1) 60%,
        rgba(235, 230, 227, 1) 80%, 
        #f3edec 100%
    )`;

    return (
        <Box bg={bg} {...rest}>
            {children}
        </Box>
    );
}

export default HeroGradient;
