import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Skeleton,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { motion } from "framer-motion";
import { useHero } from "./Hero.hooks";
import XLogo from "../../assets/images/Xlogo.png"
import HeroGradient from "../common/HeroGradient";




const FileInput: React.FC = () => {
  const [fileContent, setFileContent] = useState<string>("");

  const curriedAdd: (a: number) => (b: number) => (c: number) => number = (a) => (b) => (c) => a + b + c

  console.log(curriedAdd(1)(2)(3))
  // Read file content to ArrayBuffer
  function readFileToArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // Register callback function when file reading is complete
      reader.onload = function (event) {
        const arrayBuffer = event.target?.result;
        if (arrayBuffer instanceof ArrayBuffer) {
          resolve(arrayBuffer);
        } else {
          reject(new Error("File reading did not result in an ArrayBuffer"));
        }
      };

      reader.onerror = function (error) {
        reject(error);
      };

      // Read file content to ArrayBuffer
      reader.readAsArrayBuffer(file);
    });
  }

  // Convert ArrayBuffer to hexadecimal string
  function arrayBufferToHexString(arrayBuffer: ArrayBuffer): string {
    const uint8Array = new Uint8Array(arrayBuffer);
    let hexString = "";
    for (let i = 0; i < uint8Array.length; i++) {
      const hex = uint8Array[i].toString(16).padStart(2, "0");
      hexString += hex;
    }
    return hexString;
  }

  // Handle file select event
  function handleFileChange(event: ChangeEvent<HTMLInputElement>): void {
    const file = event.target.files?.[0]; // Get selected file

    if (file) {
      readFileToArrayBuffer(file)
        .then(arrayBuffer => {
          const hexString = arrayBufferToHexString(arrayBuffer);
          setFileContent(hexString);
        })
        .catch(error => {
          console.error("File read failed:", error);
        });
    } else {
      setFileContent("Please select a file");
    }
  }

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <div>
        <h4>File content:</h4>
        <pre>{fileContent}</pre>
      </div>
    </div>
  );
};

export default function Hero() {
  const {
    user, icons, MotionBox, heroGradientLoaded, setHeroGradientLoaded, heroGradientError, HeroGradient: heroG, setHeroGradientError,

  } = useHero();
  return (
    <HeroGradient
      as={Flex}
      pos="relative"
      zIndex={20}
      alignItems="flex-start"
      py={{ base: 20, md: 36 }}
      flexDir="column"
      px={{ sm: "4%", md: "0" }}
      w="100%"
      // bgColor={useColorModeValue("#1E2832", "gray.900")}
      bgImage={heroG}
      textAlign="left"
      bgRepeat="no-repeat"
      bgSize="cover"
      h={{base: "60vh", md:"83.5vh"}}
    >

        <VStack
          pl={{ sm: "2%", md: "10%" }}
          pr={{ sm: "2%", md: "10%" }}
          w={{ sm: "100%", md: "80%" }}
          alignItems="left"
          textAlign="left"
          justifyContent="flex-start"
        // m="0 auto"
        >
          <HStack display={{ base: "none", lg: "inline-block" }}><Flex
            pos="absolute"
            zIndex={25}
            left="750px"
            top="67px"
            justify="center"
            pt="2rem"
            align="center"
            bg="transparent"
          >
            {icons.slice(0, 1).map((item, index) => (
              <Box as="a"
                key={index}
                href={item.link}
                target="blank"
                boxSize="fit-content"
                _hover={{ textDecoration: 'none' }}
              >
                <MotionBox
                  zIndex={25}
                  pos="relative"
                  bg="rgba(255, 255, 255, 0.2)"
                  borderRadius="50%"
                  boxSize="45px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  border="1px solid rgba(255, 255, 255, 0.3)"
                  backdropBlur="5px"
                  m={4}
                  boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)"
                  animate={{
                    y: [0, -50, 0],
                  }}
                  _hover={{ cursor: 'pointer', boxShadow: 'xl' }}
                  transition={{
                    duration: 4,
                    ease: 'easeInOut',
                    repeat: Infinity,
                    delay: index * 3.5, // Delay for each icon
                  }}
                >
                  {typeof item.icon === 'string' ? (
                    <Image src={XLogo} alt={item.label} boxSize={4} />
                  ) : (
                    <Icon as={item.icon} boxSize={4} color="blue.500" />
                  )}
                </MotionBox>
              </Box>
            ))}
          </Flex><Flex
            pos="absolute"
            zIndex={25}
            right="320px"
            top="230px"
            justify="center"
            pt="12rem"
            align="center"
            bg="transparent"
          >
              {icons.slice(1, 2).map((item, index) => (
                <Box as="a"
                  key={index}
                  href={item.link}
                  target="blank"
                  _hover={{ textDecoration: 'none' }}
                >
                  <MotionBox
                    zIndex={25}
                    pos="relative"
                    bg="rgba(255, 255, 255, 0.2)"
                    borderRadius="50%"
                    boxSize="45px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid rgba(255, 255, 255, 0.3)"
                    backdropBlur="5px"
                    m={4}
                    boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)"
                    animate={{
                      y: [0, -100, 0],
                    }}
                    _hover={{ cursor: 'pointer', boxShadow: 'xl' }}

                    transition={{
                      duration: 50,
                      ease: 'easeInOut',
                      repeat: Infinity,
                      delay: index * 3.5, // Delay for each icon
                    }}
                  >
                    {typeof item.icon === 'string' ? (
                      <Image src={XLogo} alt={item.label} boxSize={4} />
                    ) : (
                      <Icon as={item.icon} boxSize={4} color="blue.500" />
                    )}
                  </MotionBox>
                </Box>
              ))}
            </Flex>
            <Flex
              pos="absolute"
              zIndex={25}
              top="220px"
              right="140px"
              justify="center"
              pt="12rem"
              align="center"
              bg="transparent"
            >
              {icons.slice(2, 3).map((item, index) => (
                <Box as="a"
                  key={index}
                  href={item.link}
                  target="blank"
                  _hover={{ textDecoration: 'none' }}
                >
                  <MotionBox
                    zIndex={25}
                    pos="relative"
                    bg="rgba(255, 255, 255, 0.2)"
                    borderRadius="50%"
                    boxSize="80px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid rgba(255, 255, 255, 0.3)"
                    backdropBlur="5px"
                    m={4}
                    boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)"
                    animate={{
                      y: [0, -190, 0],
                    }}
                    _hover={{ cursor: 'pointer', boxShadow: 'xl' }}

                    transition={{
                      duration: 40,
                      ease: 'easeInOut',
                      repeat: Infinity,
                      delay: index * 2.5, // Delay for each icon
                    }}
                  >
                    {typeof item.icon === 'string' ? (
                      <Image src={XLogo} alt={item.label} boxSize={4} />
                    ) : (
                      <Icon as={item.icon} boxSize={4} color="blue.500" />
                    )}
                  </MotionBox>
                </Box>
              ))}
            </Flex> <Flex
              pos="absolute"
              zIndex={25}
              top="250px"
              right="20px"
              justify="center"
              pt="12rem"
              align="center"
              bg="transparent"
            >
              {icons.slice(3).map((item, index) => (
                <Box as="a"
                  key={index}
                  href={item.link}
                  target="blank"
                  _hover={{ textDecoration: 'none' }}
                >
                  <MotionBox
                    // initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0 }}
                    zIndex={25}
                    pos="relative"
                    bg="rgba(255, 255, 255, 0.2)"
                    borderRadius="50%"
                    boxSize="80px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid rgba(255, 255, 255, 0.3)"
                    backdropBlur="5px"
                    m={4}
                    boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)"
                    animate={{
                      y: [0, -90, 0],
                    }}
                    _hover={{ cursor: 'pointer', boxShadow: 'xl' }}

                    transition={{
                      duration: 40,
                      ease: 'easeInOut',
                      repeat: Infinity,
                      delay: index * 2.5, // Delay for each icon
                    }}
                  >
                    {typeof item.icon === 'string' ? (
                      <Image color="red" src={XLogo} alt={item.label} boxSize={4} />
                    ) : (
                      <Icon as={item.icon} boxSize={4} color="blue.500" />
                    )}
                  </MotionBox>
                </Box>
              ))}
            </Flex>
          </HStack>
          <MotionBox
            initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}
          >
            <Heading
              as="h1"
              className="animate__animated animate__fadeIn"
              fontWeight={900}
              lineHeight={1}
              marginBottom="40px"
              fontSize={{ sm: "40px", md: "80px" }}
              textAlign={{ base: "center", md: "left" }}
            >
              <Text as="span" color={useColorModeValue("white", "#183164")}>Craft</Text> your amazing <Text as="span" color={useColorModeValue("primary", "#f2f4f8")}>Trainings</Text>
            </Heading>
            <Text
              className="animate__animated animate__fadeIn"
              marginBottom="24px"
              fontSize={{ sm: "sm", md: "20px" }}
              lineHeight={1.35}
              textAlign="left"
            >
              Liahtutor pioneers visual collaboration for e-learning tutors and
              empowers them to create training programs with ease.
            </Text>
            <HStack
              className="animate__animated animate__fadeIn"
              flexDir={{ base: "column", sm: "column", md: "row" }}>
              {user ? (
                <Button
                  zIndex={1}
                  as={Link}
                  display={{ base: "none", md: "inline-flex" }}
                  fontSize={"sm"}
                  fontWeight={600}
                  color={"white"}
                  bg={"#082D84"}
                  px="30px"
                  to={"/dashboard"}
                  _hover={{
                    bg: "#1944ab",
                  }}
                >
                  Dashboard{" "}
                </Button>
              ) : (
                <Button
                  zIndex={1}
                  as={Link}
                  fontSize={"sm"}
                  fontWeight={600}
                  variant="primary"
                  to={"/signup"}
                >
                  Start For Free
                </Button>
              )}

              <Button
                zIndex={1}
                as={Link}
                fontSize={"sm"}
                fontWeight={600}
                variant="outline"
                to="/contact-us"

                rightIcon={<FiArrowRight />}
              >
                Questions? Talk to us
              </Button>
            </HStack>
          </MotionBox>
        </VStack>
        <VStack>
          {/* <FileInput /> */}
        </VStack>
      </HeroGradient>
  );
}
