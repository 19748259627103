import {
  Box,
  Flex,
  Icon,
  IconButton,
  List,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { LinkItemProps, NavItem } from "../Pages/UserZone/UserZone";
import {
  FiChevronsRight,
  FiHome,
  FiMessageCircle,
  FiSettings,
  FiTrendingUp,
} from "react-icons/fi";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Logo } from "../components/common/Logo";
import Nav from "../components/Navbar/Nav";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import MobileSidebar from "../components/Sidebar/MobileSidebar";
import { IconType } from "react-icons";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Helmet } from "react-helmet-async";
import { programActions } from "../store/features/slices/trainingPrograms";
import { FaHeadset } from "react-icons/fa";
import DashboardFooter from "../components/Footer/DashboardFooter";
import { principalTokenKey } from "../constants";
import { useCreateTPLayout, useToggleSideBar } from "../components/CreateTrainingProg/createTp.hooks";

const LinkItems: LinkItemProps[] = [
  { name: "Dashboard", icon: FiHome, to: "/dashboard" },
  { name: "My Programs", icon: AiOutlineSafetyCertificate, to: "programs" },
  { name: "Performance", icon: FiTrendingUp, to: "performance" },
  { name: "Communication", icon: FiMessageCircle, to: "communication" },
  { name: "Settings", icon: FiSettings, to: "settings" },
];

interface SidebarIconProps {
  linkTo: string;
  linkName: string;
  icon: IconType;
}
const SidebarIcon = ({ linkName, linkTo, icon }: SidebarIconProps) => {
  // const { isOpen, onToggle, onClose } = useDisclosure();
  const colorTheme = useColorModeValue("inherit", "inherit");
  const location = useLocation();
  return (
    // <Popover
    //   returnFocusOnClose={false}
    //   isOpen={isOpen}
    //   onClose={onClose}
    //   placement="right"
    //   closeOnBlur={true}
    // >
    //   <PopoverTrigger>
    <IconButton
      // onMouseEnter={onToggle}
      // onMouseLeave={onToggle}
      aria-label={linkName}
      title={linkName}
      as={Link}
      to={linkTo}
      // title={linkName}
      isActive={
        location.pathname.includes(`/${linkTo}`) ||
        (location.pathname === "/dashboard" && linkName === "Dashboard")
      }
      _active={{
        bg: "primary",
      }}
      variant="outline"
      borderRadius="50%"
      icon={
        <Icon
          fontSize="18"
          color={
            location.pathname.includes(`/${linkTo}`) ||
              (location.pathname === "/dashboard" && linkName === "Dashboard")
              ? "white"
              : "unset"
          }
          _groupHover={{
            color: colorTheme,
          }}
          as={icon}
        />
      }
    />
    // </PopoverTrigger>
    // <PopoverContent pos="relative" zIndex={29} w="fit-content" outline={"none"} p={2}>
    //   {linkName}
    // </PopoverContent>
    // </Popover>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const colorTheme = useColorModeValue("inher it", "inherit");
  const { toggleSidebar, dashboardSidebarOpen } = useToggleSideBar()

  // useEffect(() => {
  //   const preferredSidebar = localStorage.getItem("preferredSidebar");
  //   if (preferredSidebar) {
  //     if (preferredSidebar === "expand") {
  //       setCollapse(true);
  //       return;
  //     } else if (preferredSidebar === "preferredSidebar==='expand'") {
  //       setCollapse(false);
  //     }
  //   }
  // }, [setCollapse]);



  return (
    <Box
      display={{
        base: "none",
        lg: "block",
      }}
      h="100vh"
      transition="1s ease"
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: dashboardSidebarOpen ? 60 : 50 }}
      position="sticky"
      top={0}
    >
      <Box h={"100%"} position="sticky" top={0} zIndex={10}>
        {" "}
        <Flex
          h="20"
          mx={dashboardSidebarOpen ? "8" : 0}
          justifyContent={dashboardSidebarOpen ? "unset" : "center"}
        >
          {<Logo liahtutor={dashboardSidebarOpen} linkTo="/" />}
          {/* <CloseButton display={{ base: "flex", md: "none" }} /> */}
        </Flex>
        {dashboardSidebarOpen &&
          LinkItems &&
          LinkItems.map((link) => (
            <NavItem
              to={link.to}
              key={link.name}
              icon={link.icon}
              isActive={
                location.pathname.includes(`/${link.to}`) ||
                (location.pathname === "/dashboard" &&
                  link.name === "Dashboard")
              }
            >
              {link.name}
            </NavItem>
          ))}
        <VStack gap="6" px="2">
          {!dashboardSidebarOpen &&
            LinkItems &&
            LinkItems.map((link) => (
              <SidebarIcon
                key={link.name}
                icon={link.icon}
                linkName={link.name}
                linkTo={link.to}
              />
            ))}
        </VStack>
        <VStack px="2" pos="absolute" bottom={3} w="full">
          <Flex
            rounded={"md"}
            bg="primary"
            justifyContent={dashboardSidebarOpen ? "space-between" : "center"}
            alignItems={dashboardSidebarOpen ? "center" : "unset"}
            w="full"
            p={2}
            flexDir={dashboardSidebarOpen ? "row" : "column"}
            gap={2}
          >
            {" "}
            <Icon
              fontSize="24"
              _groupHover={{
                color: colorTheme,
              }}
              color={"white"}
              cursor={"pointer"}
              onClick={() => toggleSidebar()}
              as={FiChevronsRight}
              style={
                dashboardSidebarOpen
                  ? {
                    transform: "rotate(180deg)",
                    transition: "all.25s ease-in-out",
                  }
                  : {
                    transition: "all.25s ease-in-out",
                    transform: "rotate(0deg)",
                  }
              }
            />
            <Link to={"support"}>
              <Icon
                // onClick={() => navigate("/contact-us")}
                fontSize="20"
                _groupHover={{
                  color: colorTheme,
                }}
                title="Contact Support"
                color={"white"}
                cursor={"pointer"}
                as={FaHeadset}
              />
            </Link>
          </Flex>
        </VStack>
      </Box>
    </Box>
  );
};

type MobileSidebarItemsProps = {
  toggleSidebar: () => void;
};
const MobileSidebarItems = ({ toggleSidebar }: MobileSidebarItemsProps) => {
  const location = useLocation();

  return (
    <Box>
      <Flex h={"3em"} justifyContent={"center"}>
        <Logo
          title="logo"
          linkTo="/"
          onClick={() => toggleSidebar()}
        />
      </Flex>
      <List>
        {LinkItems &&
          LinkItems.map((link) => (
            <NavItem
              to={link.to}
              key={link.name}
              icon={link.icon}
              isActive={
                location.pathname.includes(`/${link.to}`) ||
                (location.pathname === "/dashboard" &&
                  link.name === "Dashboard")
              }
              onClick={() => toggleSidebar()}
            >
              {link.name}
            </NavItem>
          ))}
      </List>
    </Box>
  );
};
const DashoardLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { payload } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Prevent scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Enable scrolling when component unmounts
    };
  }, [isOpen]);

  useEffect(() => {
    const token = localStorage.getItem(principalTokenKey)
    if (token) {
      dispatch(programActions.getMyTrainingPrograms(token));
    }
    else {
      console.log("there")
      navigate('/signin')
    }
  }, []);

  if (!payload?.user) {
    navigate("/signin");
  }

  return (
    <>
      <Helmet>
        <title>Dashboard | Liahtutor</title>
        <meta
          name="description"
          content="Dashboard of the tutor"
        />
      </Helmet>{" "}
      <Flex
        h="100vh"
        textAlign="start"
        fontFamily={"Montserrat"}
        // position={"relative"}
        overflowX="hidden"
      >
        <Sidebar />
        <MobileSidebar
          isOpen={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          children={
            <MobileSidebarItems toggleSidebar={() => setIsOpen(!isOpen)} />
          }
        />
        <Box
          w={{ base: "100%", md: "calc(100vw-60)" }}
          bg={useColorModeValue("#F5F7FA", "gray.800")}
          // pos="relative"
          minH="100vh"
          h={"fit-content"}
        >
          <Nav
            handlerSidebar={() => {
              setIsOpen(!isOpen);
            }}
          />{" "}
          <Outlet />
          {/* <Box overflow="hidden" pos="absolute" bottom={0} w="85%">
          <DashboardFooter />
          </Box> */}
        </Box>
      </Flex>
    </>
  );
};

export default DashoardLayout;