import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  useToast,
  ModalCloseButton,
  Button,
  Spinner,
  useColorModeValue,
  Text,
  Center,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setLoading,
  setModalContent,
  toggleModal,
} from "../../store/features/slices/utitlitySlice";
import authService from "../../services/auth.service";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { CloseIcon } from "@chakra-ui/icons";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  onOpen?: boolean;
};

// const IsLoading = () => {
//   const dispatch = useAppDispatch();
//   const [close, setClose] = useState(false);

//   useEffect(() => {
//     setTimeout(() => {
//       setClose(true);
//     }, 40000);
//   }, []);
//   const handleClose = () => {
//     // dispatch(setModal(false));
//     dispatch(setLoading(false));
//   };
//   return (
//     <Flex
//       className="animate__fadeIn animate__bounceOut"
//       position={"fixed"}
//       top={0}
//       left={0}
//       zIndex={"1000"}
//       h="100vh"
//       w="100vw"
//       bg={"none"}
//     >
//       <Box m="auto">
//         <Spinner
//           thickness="4px"
//           speed="0.65s"
//           emptyColor="gray.200"
//           color="blue.500"
//           size="xl"
//         />
//         {close && (
//           <CloseIcon
//             pos={"absolute"}
//             right={3}
//             top={3}
//             color={"primary"}
//             cursor={"pointer"}
//             onClick={() => handleClose()}
//           />
//         )}
//       </Box>
//     </Flex>
//   );
// };
// const BackDrop = () => {

//   const closeModal = () => {
//     // dispatch(setModal(false));
//   };
//   return (
//     <Box
//       className="backdrop"
//       onClick={closeModal}
//       h={"100vh"}
//       w={"100vw"}
//       position={"fixed"}
//       top={"0"}
//       left={"0"}
//       bg={useColorModeValue("rgba(0,0,0,0.5)", "rgba(0,0,0,0.8)")}
//       zIndex={"999"}
//     />
//   );
// };
// const Modald = () => {
//   const { isModalOpen, isLoading } = useAppSelector((state) => state.utility);

//   const backdrop = document.getElementById("backdrop");
//   const modal = document.getElementById("modal");

//   return (
//     <>
//       {(isLoading || isModalOpen) &&
//         backdrop &&
//         ReactDom.createPortal(<BackDrop />, backdrop)}
//       {/* {isModalOpen && modal && ReactDom.createPortal(<ModalContent />, modal)} */}
//       {isLoading && modal && ReactDom.createPortal(<IsLoading />, modal)}
//     </>
//   );
// };

const handleSignOut = ({
  toast,
  closeModal,
  navigate,
}: {
  toast: any;
  closeModal: () => void;
  navigate: NavigateFunction;
}) => {
  authService.logout();
  toast({
    title: "Sign out",
    description: "Signed out successfully",
    status: "success",
    duration: 9000,
    isClosable: true,
  });
  closeModal();
  navigate("/");
};
export const SignOut = () => {
  const toast = useToast();
  const navigate = useNavigate();
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const closeModal = () => {
    dispatch(toggleModal(false));
  };
  return (
    <>
      {/* <ModalCloseButton /> */}
      <ModalHeader>Are you sure?</ModalHeader>

      <ModalFooter>
        <Button colorScheme="green" mr={3} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          colorScheme="red"
          onClick={() => handleSignOut({ toast, navigate, closeModal })}
        >
          Sign out
        </Button>
      </ModalFooter>
    </>
  );
};
export const Overley = ({ isOpen, onClose, onOpen }: Props) => {

  // const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const closeModal = () => {
    dispatch(toggleModal(false));
  };

  // const SignOut
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Are you sure?</ModalHeader>

        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={closeModal}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={SignOut}>
            Sign out
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface LoadingProps {
  message?: string;
  content?: React.ReactElement | React.ReactNode;
}
export const Loading: React.FC<LoadingProps> = ({ message, content }) => {
  const dispatch = useAppDispatch();
  const [close, setClose] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setClose(true);
    }, 10000); // Adjusted timeout to 10 seconds (10000 milliseconds)

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  const handleClose = () => {
    dispatch(
      setModalContent({
        content: null,
        modalState: false,
        isLoading: false,
        isCentered: false,
      })
    );
  };

  return (
    <Center flexDir="column" gap={3}>
      <Spinner speed="0.45s" emptyColor="gray.200" color="blue.500" size="sm" />
      {content && <>{content}</>}
      {message && (
        <Text fontSize={16} color="white">
          {message}
        </Text>
      )}
      {close && (
        <CloseIcon
          pos="absolute"
          right={3}
          top={3}
          color="primary"
          cursor="pointer"
          onClick={handleClose}
        />
      )}
    </Center>
  );
};

export const CustomizedModal = () => {
  const dispatch = useAppDispatch();
  const {
    modalContent,
    isModalOpen,
    modalIsCentered,
    modalCloseOnOverlayClick,
    isLoading,
  } = useAppSelector((state) => state.utility);
  const closeModal = () => {
    dispatch(toggleModal(false));
  };
  const colorTheme = useColorModeValue("white", "gray.600");

  useEffect(() => {
    if (!isModalOpen) {
      dispatch(setLoading(false));
    }
  }, [isModalOpen, dispatch]);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeModal}
      isCentered={modalIsCentered}
      closeOnOverlayClick={modalCloseOnOverlayClick}
    >
      <ModalOverlay />
      <ModalContent
        bg={isLoading ? "transparent" : colorTheme}
        shadow={isLoading ? "unset" : "lg"}
        justifyContent={"center"}
        alignItems={"center"}
        minH="6em"
        overflow={"hidden"}
      >
        {modalContent}
      </ModalContent>
    </Modal>
  );
};
