import Hero from "../components/Hero/Hero";
import Carousel from "../components/Carousel/Carousel";
import SeeAndBuild from "../components/SeeAndBuild/SeeAndBuild";
import LargeWithLogoCentered from "../components/Footer/Footer";
import Newsletter from "../components/Newsletter/Newsletter";
import { Box, VStack } from "@chakra-ui/react";
import Collaborators from "../components/Collaborators/Collaborators";
import { HeaderRoutes } from "../utils/HeaderROutes";
import ImageGrid from "../components/ImageGrid/ImageGrid";
import { Helmet } from "react-helmet-async";

export default function Landing() {
  return (
    <Box>
      <Helmet>
        <title>LiahTutor | Craft Amazing Trainings</title>
        <meta name="author" content="Liah Technologies" />
        <meta name="keywords" content="LiahTutor, Liah, Liah-Study, LiahStudy, Tekstedia, LiahTech, LiahTechnologies, Liah-Technologies online training, e-learning, education, training program, online courses" />

        <meta
          name="description"
          content="Tutor platform for LiahStudy Enhance e-learning"
        />
      </Helmet>
      <HeaderRoutes />
      <VStack spacing={0}>

        <Hero />
        <ImageGrid />
        <Box h={{base: "25vh", md: "60vh"}} />
        {/* <Carousel /> */}
        <SeeAndBuild />
        <Collaborators />
        <Newsletter />
        <LargeWithLogoCentered />
      </VStack>
    </Box>
  );
}
