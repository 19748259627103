import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiException, PrincipalResponse } from '../../../types';
import {
  getPrograms,
  getTutorsForProgram,
  subscribeToNewsletter,

} from '../../../api/trainingProgram.api';

import { AsyncState, retrieveErrorResponse } from '../../../utils';

// Define the initial state
const initialState: AsyncState<string, ApiException> = {
  loading: true,
  payload: "",
  errors: [],
};

// Async thunks
export const subscribe = createAsyncThunk(
  'newsletter/subscribe',
  async (email:string, thunkApi) => {
    try {
      const info = await subscribeToNewsletter(email);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);


export const newsLetterSlice = createSlice({
  name: 'newsletter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(subscribe.pending, (state) => {
        state.loading = true;
      })
      .addCase(subscribe.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action.payload;
      })
      .addCase(subscribe.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      })

  },
});




// Export actions
export const newsLetterActions = {
    subscribe

};

// Export the reducer function
export default newsLetterSlice.reducer;
