import {
  Box,
  Button,
  Card,
  Divider,
  HStack,
  PinInput,
  PinInputField,
  Text,
  VStack,
  Icon,
} from "@chakra-ui/react";
import { Logo } from "../../components/common/Logo";
import { Helmet } from "react-helmet-async";
import { useOtpConfirmation } from "../../hooks/auth.hooks";
import { EmailIcon } from "@chakra-ui/icons";

type Props = {};

const Otp = (props: Props) => {
  const { isLoading, emailToUse, handleConfirmOTP, value1, value2, value3, value4 } =
    useOtpConfirmation();

  return (
    <Card
      className="animate__animated animate__fadeIn"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      minH={{
        base: "100%",
        md: "50vh",
      }}
      p={{
        base: "1em",
        md: "2em",
      }}
      pos="absolute"
      right={0}
      top={0}
      h="100%"
      boxShadow="none"
      borderRadius={0}
      textAlign="left"
      flexDirection={"column"}
      gap={"1em"}
      w={{
        base: "100%",
        md: "30%",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Verify email| Liahtutor</title>
        <link rel="canonical" href="https://teach.liahstudy.online" />
      </Helmet>
      <Logo />
      <Text as="h3" textAlign={"center"} fontWeight={600} fontSize="16">
        Verify your email with OTP
      </Text>
      <Box textAlign="center">
          <Icon as={EmailIcon} w={6} h={6} color="primary" />
          <Text fontSize="14" color="gray.500">
            {emailToUse}
          </Text>
        </Box>
      <HStack color={"primary"}>
        <PinInput type="alphanumeric">
          <PinInputField w={"4em"} h={"6em"} ref={value1} />
          <PinInputField w={"4em"} h={"6em"} ref={value2} />
          <PinInputField w={"4em"} h={"6em"} ref={value3} />
          <PinInputField w={"4em"} h={"6em"} ref={value4} />
        </PinInput>
      </HStack>
      <VStack
        h={{
          base: "100%",
          md: "100%",
        }}
        flexDir={{
          base: "column",
          md: "column",
        }}
        alignItems={"center"}
        justifyContent={"space-evenly"}
        w="20vw"
      >
        <Button
          type="submit"
          variant={"primary"}
          w="100%"
          onClick={handleConfirmOTP}
          isLoading={isLoading}
          loadingText={"Loading..."}
        >
          Confirm
        </Button>
        {/* <HStack w="100%" justifyContent={"space-between"}>
          <Text
            cursor={"pointer"}
            as={Link}
            fontSize={12}
            to="/auth/email"
            w="fit-content"
            color={"primary"}
          >
            Wrong Email?
          </Text>
        </HStack> */}
        <Box position="relative" py="10" w="100%">
          <Divider />
        </Box>
        
        <Box pos="absolute" bottom={9}>
          <Logo />
        </Box>
      </VStack>
    </Card>
  );
};

export default Otp;
