import React, { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { principalTokenKey } from '../constants';
import { selectAuthPayload } from '../utils/authSelector';
import Loading from '../components/loading/Loading';

type AuthGuardProps = {
  children: React.ReactNode;
  failTo: string;
  guardWhat?: string;
};

export const AuthGuard: React.FC<AuthGuardProps> = ({ children, failTo, guardWhat }) => {
  const { payload, loading } = useAppSelector(selectAuthPayload); // Use memoized selector


  const location = useLocation(); // Get current location

  // Use memoization to avoid recalculating token and auth status on every render
  // Memoize authentication status to avoid recalculating unnecessarily
  const isAuthenticated = useMemo(() => {
    const token = localStorage.getItem(principalTokenKey);

    // Use token-based authentication fallback while payload is loading
    if (!loading && token) {
      if (!payload?.user) {
        // console.log("Waiting for user data but token is available");
        return true; // Token exists, wait for Redux to populate the user data
      }
      // When payload is fully available, check email verification
      return payload?.user && payload?.user.emailVerified === true && !!token;
    }
    return false; // Not authenticated
  }, [payload, loading]);

  // Render nothing if still loading (you can replace this with a spinner or skeleton)
  if (loading) return <Loading />;

  // If authenticated and the user is trying to access the signin route, redirect to the dashboard
  if (isAuthenticated && location.pathname === '/signin') {
    console.log("User is already authenticated, redirecting to dashboard");
    return <Navigate to='/dashboard' />;
  }

  // Redirect to `failTo` route if the user is not authenticated
  if (!isAuthenticated) {
    console.log("Redirecting due to failed authentication");
    return <Navigate to={failTo} />;
  }

  // Log the guarded route for debugging purposes, if needed
  if (guardWhat) {
    console.log(`Guarding: ${guardWhat}`);
  }

  // If authenticated and not on the /signin route, render the children
  return <>{children}</>;
};
