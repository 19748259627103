import { useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { TrainingProgram } from "../../types";
import { principalTPKey } from "../constants";

export const useQueryParams = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  return Object.fromEntries(query.entries());
};

export const useGetParams = () => {
  //   const location = useLocation();
  //   return location.;
};

export const useGetProgramByIdFromUrl = () => {
  const { programId } = useParams<{ programId: string }>();

  if (!programId) {
    throw new Error("Program id is not provided in the URL parameters.");
  }

  const storedTrainingPrograms = localStorage.getItem(principalTPKey);
  const parsedTrainingPrograms = storedTrainingPrograms
      ? JSON.parse(storedTrainingPrograms)
      : {};

  const allPrograms: TrainingProgram[] = parsedTrainingPrograms.payload?.trainingProgram?.programs || [];

  // const formattedProgramTitle = programId
  //     .toLowerCase()
  //     .replace(/-/g, " ");

  const programExist = allPrograms.find(
      (program) => program._id === programId
  );

  return {
    programx: programExist,
    programId,
  };
};
